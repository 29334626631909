.ha-website-carousel-container {
  width: 100%;
  max-width: 1280px;
  box-sizing: border-box;
  position: relative;

  .ha-website-star-content {
    padding: 4rem !important;
  }

  .ha-website-button {
    background-color: unset !important;
  }
}

.ha-website-carousel-content-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.15s ease-in-out;

  .ha-website-carousel-scrubber {
    display: flex;
    flex-direction: row;
  }
}

.ha-website-carousel-image-container {
  width: 100%;
  z-index: 2;
  position: relative;

  img {
    width: 100%;
    transform: perspective(1000px) rotateX(355deg) rotateY(1deg) rotateZ(1deg);
    background-color: #080324;

    &.modifier-1 {
      transform: perspective(1000px) rotateX(5deg) rotateY(1deg) rotateZ(1deg);
    }

    &.modifier-2 {
      transform: perspective(1000px) rotateX(355deg) rotateY(1deg)
        rotateZ(-1deg);
    }
  }
}

@keyframes resize {
  0% {
    opacity: 1;
    width: 0%;
    left: 0;
  }
  50% {
    opacity: 1;
    width: 100%;
    left: 0;
  }
  100% {
    opacity: 1;
    width: 100%;
    left: 100%;
  }
}

@keyframes resizeTwo {
  0% {
    opacity: 1;
    width: 0%;
    right: 100%;
  }
  50% {
    opacity: 1;
    width: 100%;
    right: 100%;
  }
  100% {
    opacity: 1;
    width: 0%;
    right: 0%;
  }
}

@keyframes rotateAnimateForward {
  0% {
    transform: translateX(0%) rotate(0deg) perspective(1000px) rotateX(355deg)
      rotateY(1deg) rotateZ(1deg) scale(1, 1);
    opacity: 1;
  }
  49% {
    transform: translateX(-35%) rotate(5deg) scale(1.2, 1.2);
    opacity: 0;
  }
  50% {
    transform: translateX(35%) rotate(-5deg) scale(1.2, 1.2);
    opacity: 0;
  }
  100% {
    transform: translateX(0%) rotate(0deg) perspective(1000px) rotateX(355deg)
      rotateY(1deg) rotateZ(1deg) scale(1, 1);
    opacity: 1;
  }
}

@keyframes rotateAnimateBackward {
  0% {
    transform: translateX(0%) rotate(0deg) perspective(1000px) rotateX(355deg)
      rotateY(1deg) rotateZ(1deg) scale(1, 1);
    opacity: 1;
  }
  49% {
    transform: translateX(35%) rotate(-5deg) scale(1.2, 1.2);
    opacity: 0;
  }
  50% {
    transform: translateX(-35%) rotate(5deg) scale(1.2, 1.2);
    opacity: 0;
  }
  100% {
    transform: translateX(0%) rotate(0deg) perspective(1000px) rotateX(355deg)
      rotateY(1deg) rotateZ(1deg) scale(1, 1);
    opacity: 1;
  }
}

.animate {
  &.forward {
    img {
      animation: rotateAnimateForward 0.4s forwards ease-in-out;
    }
  }
  &.backward {
    img {
      animation: rotateAnimateBackward 0.4s forwards ease-in-out;
    }
  }
}

.animate {
  overflow: visible;

  .ha-website-carousel-transition-1,
  .ha-website-carousel-transition-2 {
    animation: resize 0.4s forwards ease-in-out;
  }
}

.ha-website-carousel-transition-1,
.ha-website-carousel-transition-2,
.ha-website-carousel-transition-3 {
  opacity: 0;
  width: 100%;
  // height: 33.33%;
  height: 50%;
  background-color: white;
  position: absolute;
  left: 0;
  z-index: 1;
}

.ha-website-carousel-transition-2 {
  // top: 33.33%;
  top: 50%;
  background-color: #080324;
  right: 100%;
}

.ha-website-carousel-transition-3 {
  top: 66.66%;
  left: 0;
}

@keyframes rotateCarousel {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.ha-website-carousel-star.ha-website-star {
  position: absolute;
  left: 10%;
  bottom: -15%;
  z-index: 3;

  > svg {
    width: 100%;
    height: 100%;
    animation: rotateCarousel 48s infinite forwards linear;
  }
}

.ha-website-carousel-star-year {
  z-index: 4;
  left: 5%;
  bottom: 35%;

  > svg {
    width: 100%;
    height: 100%;
    animation: rotateCarousel 96s infinite forwards linear;
  }
}

.ha-website-carousel-star.ha-website-star {
  transition: all 0.15s ease-in-out;
  transform-origin: center center;
  // &:hover {
  // transform: scale(1.05);
  // }
}

.ha-website-carousel-filler {
  z-index: 0;
  position: absolute;
  bottom: 0;
  background-color: #080324;
  height: 50%;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  // transition: all 0.3s ease-in;
}

.ha-website-grid-filler-carousel {
  padding-top: 6rem;
}

@media screen and (max-width: 800px) {
  .ha-website-carousel-filler {
    height: 90%;
  }
  .ha-website-carousel-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .ha-website-carousel-star {
    margin-top: 0rem;
  }
  .ha-website-carousel-star.ha-website-star {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5%;
  }
  .ha-website-carousel-star-year {
    left: 5%;
    bottom: 50%;
  }
  .ha-website-grid-filler-carousel {
    padding-top: 0rem;
  }
}
