.ha-website-use-case-star {
  position: absolute;
  right: -2.5%;
  top: -5%;
  z-index: 0;
}

.ha-website-use-case-title {
  span {
    display: block;
    width: 75%;
  }
}

.ha-website-use-case-takeaway {
  padding-right: 3rem;
  box-shadow: inset -2px 0 0 #080324;
  &.last {
    box-shadow: none;
  }
}

@media screen and (max-width: 1100px) {
  .ha-website-use-case-star {
    position: absolute;
    right: 0%;
    top: 0%;
    z-index: 0;
    width: 16rem !important;
    height: 16rem !important;

    img {
      width: 6rem;
    }
  }
  .ha-website-use-case-takeaway {
    padding-right: 3rem;
    padding-bottom: 1.5rem;
    box-shadow: none;
    &.last {
      padding-bottom: 0;
    }
  }
  .ha-website-problem-solution {
    flex-direction: column !important;

    .ha-website-problem-container {
      width: 100%;
    }
  }
  .ha-website-grid-filler-keep-padding {
    padding-top: 6rem;
  }
  .ha-website-content-key-messages {
    gap: 0;
    display: flex;

    > .ha-display-flex-row {
      flex-direction: column !important;
    }

    .ha-website-content-key-message {
      width: 100%;
      padding-bottom: 3rem;
    }
  }
}

.ha-website-problem-container {
  width: 50%;
  padding: 3rem;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 2px #080324;
  &.solution {
    background-color: #080324;
  }
}

.ha-website-content-key-message {
  flex-grow: 1;
  flex-shrink: 0;
  width: calc((100% - 3rem) / 3);

  h6.large {
    font-size: 1.5rem;
    line-height: 2rem;
    padding-right: 4rem;
  }
}
.ha-website-content-key-messages {
  width: 100%;
  position: relative;
}

@media screen and (max-width: 800px) {
  .ha-website-use-case-star {
    position: absolute;
    right: -15%;
    top: 0%;
    z-index: 0;
    width: 12rem !important;
    height: 12rem !important;

    img {
      width: 4rem;
    }
  }

  .ha-website-use-case-title {
    span {
      display: block;
      width: 90%;
    }
  }

  .bottom.ha-website-content-key-messages {
    display: none !important;
  }

  .ha-website-content-price-card {
    box-shadow: none;
  }
}
