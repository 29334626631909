@keyframes tooltipAppear {
  0% {
    left: 50%;
    top: 0;
    transform: translate(-50%, 50%) scale(0, 0);
    opacity: 0;
  }
  50% {
    left: 50%;
    top: 0;
    transform: translate(-50%, calc(-100% - 1rem)) scale(1, 1.1);
    opacity: 0.5;
  }
  100% {
    left: 50%;
    top: 0;
    transform: translate(-50%, calc(-100% - 1rem)) scale(1, 1);
    opacity: 1;
  }
}

@keyframes formAppear {
  0% {
    transform: translate(0%, 50%) scale(0, 0);
    opacity: 0;
    box-shadow: 0 0 8px 12px rgba(#080324, 0), 0 0 0 10000px rgba(#080324, 0.8);
  }
  50% {
    transform: translate(0%, 10%) scale(1, 1.1);
    opacity: 0.5;
    box-shadow: 0 0 8px 12px rgba(#080324, 0.8),
      0 0 0 10000px rgba(#080324, 0.8);
  }
  100% {
    transform: translate(0%, 0%) scale(1, 1);
    opacity: 1;
    box-shadow: 0 0 8px 12px rgba(#080324, 0), 0 0 0 10000px rgba(#080324, 0.8);
  }
}

.mc_embed_signup_scroll {
  display: flex;
  flex-direction: column;
}

.clear.foot {
  display: inline-flex;
}

.ha-website-sign-up {
  position: relative;
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;

  // transition: all 0.1s ease-in;

  #mc_embed_signup {
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    border-radius: 0.375rem;
    padding: 2rem;
    position: relative;
    box-shadow: 0 0 0 10000px rgba(#080324, 0.8);
    animation: formAppear 0.3s forwards ease-in-out;
  }

  .ha-website-input {
    width: 100%;
    box-sizing: border-box;
  }

  .ha-website-tooltip {
    pointer-events: none;
    left: 50%;
    top: 0;
    transform: translate(-50%, calc(-100% - 1rem));
    font-weight: 400;
    font-family: "Inter", sans-serif;
    display: inline-flex;
    padding: 1rem;
    border-radius: 0.375rem;
    font-size: 1rem;
    position: absolute;
    background-color: rgba(#080324, 0.95);
    color: white;
    animation: tooltipAppear 0.3s forwards ease-in-out;
  }
}

@media screen and (max-width: 800px) {
  @keyframes formAppear {
    0% {
      transform: translate(0%, 50%) scale(0, 0);
      opacity: 0;
      box-shadow: 0 0 8px 12px rgba(#080324, 0),
        0 0 0 10000px rgba(#080324, 0.8);
    }
    50% {
      transform: translate(0%, 10%) scale(1, 1.1);
      opacity: 0.5;
      box-shadow: 0 0 8px 12px rgba(#080324, 0.8),
        0 0 0 10000px rgba(#080324, 0.8);
    }
    100% {
      transform: translate(0%, 0%) scale(1, 1);
      opacity: 1;
      box-shadow: 0 0 8px 12px rgba(#080324, 0),
        0 0 0 10000px rgba(#080324, 0.8);
    }
  }

  .ha-website-sign-up {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    max-width: 100vw;

    #mc_embed_signup {
      overflow: auto;
      margin-top: 4rem;
      animation: none;
      border-radius: 0;
      height: calc(100vh - 4rem);
      animation: formAppear 0.3s forwards ease-in-out;
    }
  }

  .ha-website-click-target {
    height: 4rem !important;
    z-index: 1000000 !important;
  }
}

.ha-website-banner-prompt {
  font-family: "Inter" sans-serif;
  font-weight: 400;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #3f20fd;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background-color: rgba(#3f20fd, 0.2);
  border-radius: 0.375rem;
}

select {
  &.placeholder {
    color: rgba(#080324, 0.5);
  }
}

.ha-website-select {
  position: relative;

  select {
    width: 100%;
    appearance: none;
  }
  svg {
    right: 1rem;
    top: 0.75rem;
    position: absolute;
    opacity: 0.5;
  }
}

.ha-website-grouped-inputs {
  display: flex;
  flex-direction: row;

  input:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  input:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
