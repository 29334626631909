.ha-website-feature-content-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ha-website-text-content-padded {
  padding-right: 25%;
}

@media screen and (max-width: 1100px) {
  .ha-website-text-content-padded {
    padding-right: 0%;
  }
}
