.ha-website-logo {
    display: flex;
    outline-offset: 0px;
    outline-width: 0px;
    outline-style: solid;
    outline-color: rgba(#3F20FD, 0);

    transition: all 0.1 s ease-in;

    &:focus-visible {
        border-radius: 4px;
        outline-offset: 2px;
        outline-width: 2px;
        outline-style: solid;
        outline-color: #3F20FD;
    }
}