.ha-website-content-price-card {
  background-color: white;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  box-sizing: border-box;
  box-shadow: 0 2px 0 0 #080324;
}

.ha-website-content-prices {
  position: relative;
}

.ha-website-content-price-cards {
  width: 100%;
  .ha-website-text-content {
    font-size: 1.125rem !important;
  }
}

@media screen and (max-width: 800px) {
  .ha-website-content-price-cards {
    flex-direction: column !important;
  }
}

.ha-website-content-price-card-column {
  width: 100%;
}
