.ha-website-h1-blog.web {
  font-size: 12rem;
  line-height: 12rem;

  span {
    position: relative;
    z-index: 1;
  }

  h3 {
    font-family: "Familjen Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 10rem;
    line-height: 10rem;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ha-website-hero-blog-star {
  position: absolute;
  top: 0%;
  right: 0;
  transform: translate(5%, 0%) rotate(10deg);
  z-index: 0;

  > svg {
    width: 100%;
    height: 100%;
    animation: none;
  }
}

@media screen and (max-width: 800px) {
  .ha-website-h1-blog.web {
    font-size: 6rem;
    line-height: 6rem;

    span {
      position: relative;
      z-index: 0;
    }

    h3 {
      font-family: "Familjen Grotesk";
      font-style: normal;
      font-weight: 500;
      font-size: 4rem;
      line-height: 4rem;
    }
  }
}
