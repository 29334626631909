.ha-website-hero-star {
  top: 55%;
  right: 22.5%;
  transform: rotate(-5deg);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ha-website-hero-features-star {
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);

  h4 {
    transform: rotate(5deg);
    font-weight: 400;
  }

  > svg {
    width: 100%;
    height: 100%;
    animation: rotate 32s infinite forwards linear;
  }
}

.ha-website-hero-components-star {
  right: 0;
  top: 50%;
  transform: translate(45%, -50%);
  z-index: 0;

  h4 {
    transform: rotate(-5deg);
    font-weight: 400;
  }

  > svg {
    width: 100%;
    height: 100%;
    animation: rotate 32s infinite forwards linear;
  }
}

.ha-color-picker-selector,
.ha-color-picker-shade-selector {
  transition: all 0.1s ease-in;
}

.ha-website-component-demos {
  overflow: hidden;
}

.ha-website-integration-logo {
  height: 100%;
  max-height: 2.5rem;

  &.small {
    max-height: 2rem;
  }
}

@media screen and (max-width: 800px) {
  .ha-website-hero-star {
    top: 20%;
    right: -7.5%;
    width: 9rem !important;
    height: 9rem !important;
  }

  .ha-website-hero-features-star {
    width: 8rem !important;
    height: 8rem !important;
  }

  .ha-website-hero-components-star {
    width: 16rem !important;
    height: 16rem !important;
    right: -15%;
  }

  .ha-component-display {
    display: flex;
    flex-direction: column !important;
    gap: 0.875rem;
  }

  .ha-website-hero-blog-star {
    width: 12rem !important;
    height: 12rem !important;
    top: 25% !important;
    right: -10% !important;
  }

  .ha-website-socialproof-tools {
    flex-direction: column;
    gap: 0.875rem;
    align-items: flex-start !important;
    justify-content: flex-start;
  }
}
