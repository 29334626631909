@import url("https://fonts.googleapis.com/css2?family=Familjen+Grotesk:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

@keyframes appear {
  to {
    opacity: 1;
  }
}

.ha-website-page-appear {
  opacity: 0;
  animation: appear 0.3s forwards ease-in-out;
}

a,
div {
  &:focus {
    outline: none;
  }
}
.ha-website-container {
  width: 100vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  opacity: 0;
  animation: appear 0.3s forwards ease-in-out;

  .ha-website-content {
    display: grid;
    width: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
    max-width: 1280px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 0px;
    box-sizing: border-box;
  }

  &.dark {
    background-color: #080324;
  }
}

@media screen and (max-width: 800px) {
  .ha-website-container {
    .ha-website-content {
      overflow: hidden;
      padding-left: 2rem;
      padding-right: 2rem;
      max-width: 1280px;
    }
  }
}

@keyframes blurrer {
  0% {
    transform: translate(0%, 0%);
    // filter: blur(0px);
    opacity: 1;
  }
  49% {
    transform: translate(100%, 0%);
    // filter: blur(32px);
    opacity: 0;
  }
  51% {
    transform: translate(-100%, 0%);
    // filter: blur(32px);
    opacity: 0;
  }
  75% {
    transform: translate(-50%, 0%);
    // filter: blur(32px);
    opacity: 0;
  }
  100% {
    // filter: blur(0px);
    opacity: 1;
  }
}
.ha-website-content-animate {
  animation: blurrer 0.3s forwards linear;
}

.ha-website-input {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  height: 2.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  flex-grow: 0;
  border: 0;

  border: none;
  outline: none;
  border-radius: 0.125rem;
  outline: none;
  background: white;
  box-shadow: inset 0px 0px 0px 1px rgba(12, 12, 14, 0.15),
    inset 0px 2px 1px 0px rgba(12, 12, 14, 0.1);
  color: #080324;

  &:hover {
    box-shadow: inset 0 0 0 1px rgba(#3f20fd, 0.5),
      inset 0px 0px 0px 1px rgba(12, 12, 14, 0.15),
      inset 0px 2px 1px 0px rgba(12, 12, 14, 0.1);
  }

  &:focus {
    box-shadow: inset 0 0 0 2px rgba(#3f20fd, 1),
      inset 0px 0px 0px 1px rgba(12, 12, 14, 0.15),
      inset 0px 2px 1px 0px rgba(12, 12, 14, 0.1);
  }

  &::placeholder {
    color: rgba(#080324, 0.5);
  }

  &.large {
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 3rem;
    border-radius: 0.125rem;
  }
}

textarea.large.ha-website-input {
  height: auto;
}

.ha-website-button {
  position: relative;
  white-space: nowrap;
  font-family: "Familjen Grotesk", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  height: 2.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  flex-grow: 0;

  border: none;
  border-radius: 0.125rem;
  outline: none;
  background: #3f20fd;
  box-shadow: 0px 2px 4px rgba(46, 46, 54, 0.3);
  color: #ffffff;

  &:disabled {
    background: rgba(#3f20fd, 0.5);
    color: rgba(white, 0.5);
    box-shadow: none;
    pointer-events: none;

    &:hover {
      box-shadow: none;
    }
  }

  &.square {
    display: flex;
    width: 2.25rem;
    padding: 0;
    align-items: center;
    justify-content: space-around;
  }

  &.large {
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 3rem;
    border-radius: 0.125rem;

    &.square {
      display: flex;
      width: 3rem;
      padding: 0;
      align-items: center;
      justify-content: space-around;
    }
  }

  &.ghost {
    box-shadow: none;
    background-color: none;

    &:hover {
      cursor: pointer;
      box-shadow: inset 0px 0px 100px rgba(12, 12, 14, 0.1);
    }

    &:active {
      cursor: pointer;
      box-shadow: inset 0px 0px 100px rgba(12, 12, 14, 0.3);
    }

    &:focus-visible {
      outline-offset: 2px;
      outline-width: 2px;
      outline-style: solid;
      outline-color: white;
    }
  }

  outline-offset: 0px;
  outline-width: 0px;
  outline-style: solid;
  outline-color: rgba(#3f20fd, 0);

  transition: all 0.1 s ease-in;

  &:hover {
    cursor: pointer;
    box-shadow: inset 0px 0px 100px rgba(12, 12, 14, 0.1),
      0px 2px 4px rgba(46, 46, 54, 0.3);
  }

  &:active {
    cursor: pointer;
    box-shadow: inset 0px 0px 100px rgba(12, 12, 14, 0.3);
  }

  &:focus-visible {
    outline-offset: 2px;
    outline-width: 2px;
    outline-style: solid;
    outline-color: #3f20fd;
  }
}

.ha-website-spacer {
  display: flex;

  &-vertical {
    &-large {
      height: 6rem;
    }
    &-medium {
      height: 4rem;
    }
    &-small {
      height: 1.5rem;
    }
    &-xsmall {
      height: 1rem;
    }
  }

  &-horizontal {
    &-large {
      width: 6rem;
    }
    &-small {
      width: 1.5rem;
    }
    &-xsmall {
      width: 1rem;
    }
  }
}

.ha-website-grid {
  &-column-start {
    &-0 {
      grid-column-start: 0;
    }
    &-1 {
      grid-column-start: 1;
    }
    &-2 {
      grid-column-start: 2;
    }
    &-3 {
      grid-column-start: 3;
    }
    &-4 {
      grid-column-start: 4;
    }
    &-5 {
      grid-column-start: 5;
    }
  }
  &-column-end {
    &-0 {
      grid-column-end: 0;
    }
    &-1 {
      grid-column-end: 1;
    }
    &-2 {
      grid-column-end: 2;
    }
    &-3 {
      grid-column-end: 3;
    }
    &-4 {
      grid-column-end: 4;
    }
    &-5 {
      grid-column-end: 5;
    }
  }
}

html {
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: auto;
}

h1.web,
h2.web,
h3.web,
h4.web,
h5.web,
h6.web {
  position: relative;

  &::selection {
    background-color: #080324;
    color: white;
  }

  > span {
    &::selection {
      background-color: #080324;
      color: white;
    }
  }

  &.center {
    text-align: center;
  }
}

h1.web {
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: "Familjen Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 12rem;
  line-height: 12rem;
  color: #080324;
}

h2.web {
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: "Familjen Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 7rem;
  line-height: 7rem;
  color: #080324;
}

h4.web {
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: "Familjen Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: #080324;
}

h5.web {
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: "Familjen Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: #080324;

  &.small {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

h6.web {
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: "Familjen Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  color: #080324;
}

.ha-website-text-content {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2rem;
  color: #080324;

  &::selection {
    background-color: #080324;
    color: white;
  }

  &.center {
    text-align: center;
  }

  &.medium {
    font-size: 1.1125rem;
    line-height: 1.5rem;
  }

  &.xsmall {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}

.ha-website-white {
  color: white !important;
  &::selection {
    background-color: white;
    color: #080324;
  }
}

.ha-website-black {
  color: #080324 !important;
  &::selection {
    background-color: #080324;
    color: white;
  }
}

.ha-website-background-black {
  background-color: #080324;
}

.ha-website-background-white {
  background-color: white;
}

.ha-website-grid-long-width {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 0px;

  > * {
    z-index: 1;
  }

  .ha-website-carousel-filler {
    top: 0;
    height: 100%;
    z-index: 0;
  }
}

@media screen and (max-width: 1100px) {
  html {
    font-size: 14px;
  }

  h1.web {
    font-size: 8rem;
    line-height: 8rem;
  }

  h2.web {
    font-size: 6rem;
    line-height: 6rem;
  }

  h4.web {
    font-size: 3rem;
    line-height: 3rem;
  }

  h5.web {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  h6.web {
    font-size: 1rem;
    line-height: 1rem;
  }

  .ha-website-grid {
    &-column-start {
      &-0 {
        grid-column-start: 1;
      }
      &-1 {
        grid-column-start: 1;
      }
      &-2 {
        grid-column-start: 1;
      }
      &-3 {
        grid-column-start: 1;
      }
      &-4 {
        grid-column-start: 1;
      }
      &-5 {
        grid-column-start: 1;
      }
    }
    &-column-end {
      &-0 {
        grid-column-end: 5;
      }
      &-1 {
        grid-column-end: 5;
      }
      &-2 {
        grid-column-end: 5;
      }
      &-3 {
        grid-column-end: 5;
      }
      &-4 {
        grid-column-end: 5;
      }
      &-5 {
        grid-column-end: 5;
      }
    }
  }

  .ha-website-footer-block {
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 800px) {
  .ha-website-text-content {
    font-size: 1.25rem;
  }

  html {
    font-size: 14px;
  }

  h1.web {
    font-size: 6rem;
    line-height: 6rem;
  }

  h2.web {
    font-size: 4rem;
    line-height: 4rem;
  }

  h4.web {
    font-size: 2rem;
    line-height: 2rem;
  }

  h5.web {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  h6.web {
    font-size: 1rem;
    line-height: 1rem;
  }
}

.ha-website-content-document {
  padding: 6rem;
  box-shadow: 0 0 0 1px rgba(#080324, 0.1);
}

.ha-website-footer-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.875rem;
  font-family: "Inter", sans-serif;
  color: rgba(#080324, 0.5);
  .ha-website-navigation-content-item {
    margin-right: 1rem;
  }
  .ha-website-navigation-content-item {
    font-family: "Inter", sans-serif;
    font-size: 0.875rem;
  }
}

.dark .ha-website-footer-links {
  color: rgba(white, 0.5);
}

.ha-website-linkedin-logo {
  display: inline-flex;
  opacity: 0.25;
  transition: all 0.1s ease-in-out;
  &:hover {
    opacity: 1;
  }

  a {
    display: inline-flex;
  }
}

@media screen and (max-width: 800px) {
  .ha-website-footer-links {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start !important;
    justify-items: flex-start;
  }
}

.ha-website-investor-logo {
  background-color: white;
  padding: 2rem;
  border-radius: 0.125rem;
  margin-right: 1.5rem;
  img {
    max-height: 32px;
  }
}

@media screen and (max-width: 1100px) {
  .ha-website-investor-logos {
    flex-direction: column !important;
    align-items: flex-start;
    > .ha-website-investor-logo {
      margin-bottom: 1.5rem;
    }
  }
}

@keyframes bannerAppear {
  0% {
    transform: translate(0%, 75%) scale(0, 0);
    opacity: 0;
    box-shadow: 0 16px 48px rgba(#080324, 0.2);
  }
  50% {
    transform: translate(0%, -2%) scale(1, 1.1);
    opacity: 0.5;
    box-shadow: 0 16px 24px rgba(#080324, 1);
  }
  100% {
    transform: translate(0%, 0%) scale(1, 1);
    opacity: 1;
    box-shadow: 0 0px 0px rgba(#080324, 0);
  }
}

.ha-website-content-banner {
  background-color: #3f20fd;
  color: white;
  position: fixed;
  font-size: 1rem;
  line-height: 1.5rem;
  z-index: 10000;
  top: 6.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.25rem;
  animation: bannerAppear 0.3s forwards ease-in-out;
}

@media screen and (max-width: 1100px) {
  .ha-website-hide-on-mobile {
    display: none;
  }
}
