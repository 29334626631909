.c21 {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 500;
    font-family: 'Familjen Grotesk', sans-serif;
    margin-bottom: 1rem;
    display: block;
}

.c1:not(li) {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    font-family: 'Familjen Grotesk', sans-serif;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    display: block;
}

.c2,.c5, .c3 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
}

p.c5 {
    margin-bottom: 0.5rem;
}

p.c3 {
    margin-bottom: 0.875rem;
    display: block;
}

ul {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    font-family: 'Inter', sans-serif;

    > li {
        margin-bottom: 0.25rem;
        
    }
}
ul > li::before {
    content:"\0025aa";
    padding-right: 0.5rem;
   
}
.ha-website-privacy {
    ol.lst-kix_list_7-0{list-style-type:none}
.lst-kix_list_2-1>li{counter-increment:lst-ctn-kix_list_2-1}ol.lst-kix_list_9-0.start{counter-reset:lst-ctn-kix_list_9-0 0}ol.lst-kix_list_30-0.start{counter-reset:lst-ctn-kix_list_30-0 0}
.lst-kix_list_30-6>li{counter-increment:lst-ctn-kix_list_30-6}
.lst-kix_list_42-8>li:before{content:"\0025aa  "}
.lst-kix_list_5-0>li{counter-increment:lst-ctn-kix_list_5-0}ol.lst-kix_list_2-3.start{counter-reset:lst-ctn-kix_list_2-3 0}ol.lst-kix_list_7-5{list-style-type:none}ol.lst-kix_list_7-6{list-style-type:none}ol.lst-kix_list_7-7{list-style-type:none}ol.lst-kix_list_7-8{list-style-type:none}ol.lst-kix_list_7-1{list-style-type:none}ol.lst-kix_list_7-2{list-style-type:none}ol.lst-kix_list_7-3{list-style-type:none}ol.lst-kix_list_7-4{list-style-type:none}
.lst-kix_list_42-0>li:before{content:"\0025cf  "}
.lst-kix_list_42-1>li:before{content:"o  "}
.lst-kix_list_42-2>li:before{content:"\0025aa  "}ol.lst-kix_list_5-3.start{counter-reset:lst-ctn-kix_list_5-3 0}
.lst-kix_list_4-3>li{counter-increment:lst-ctn-kix_list_4-3}
.lst-kix_list_42-7>li:before{content:"o  "}
.lst-kix_list_42-6>li:before{content:"\0025cf  "}ol.lst-kix_list_30-5.start{counter-reset:lst-ctn-kix_list_30-5 0}
.lst-kix_list_42-4>li:before{content:"o  "}
.lst-kix_list_42-5>li:before{content:"\0025aa  "}ol.lst-kix_list_8-8.start{counter-reset:lst-ctn-kix_list_8-8 0}
.lst-kix_list_42-3>li:before{content:"\0025cf  "}
.lst-kix_list_7-2>li{counter-increment:lst-ctn-kix_list_7-2}
.lst-kix_list_24-7>li:before{content:" "}
.lst-kix_list_1-4>li{counter-increment:lst-ctn-kix_list_1-4}ul.lst-kix_list_27-1{list-style-type:none}
.lst-kix_list_24-8>li:before{content:" "}ol.lst-kix_list_1-6.start{counter-reset:lst-ctn-kix_list_1-6 0}ul.lst-kix_list_27-2{list-style-type:none}ol.lst-kix_list_9-5.start{counter-reset:lst-ctn-kix_list_9-5 0}
.lst-kix_list_24-2>li:before{content:" "}
.lst-kix_list_24-3>li:before{content:" "}
.lst-kix_list_24-4>li:before{content:" "}
.lst-kix_list_9-4>li{counter-increment:lst-ctn-kix_list_9-4}ul.lst-kix_list_27-3{list-style-type:none}
.lst-kix_list_24-5>li:before{content:" "}ul.lst-kix_list_27-4{list-style-type:none}ul.lst-kix_list_27-5{list-style-type:none}ul.lst-kix_list_27-6{list-style-type:none}ul.lst-kix_list_27-7{list-style-type:none}ul.lst-kix_list_27-8{list-style-type:none}
.lst-kix_list_24-6>li:before{content:" "}
.lst-kix_list_23-6>li:before{content:" "}
.lst-kix_list_6-5>li{counter-increment:lst-ctn-kix_list_6-5}
.lst-kix_list_23-3>li:before{content:" "}
.lst-kix_list_23-7>li:before{content:" "}
.lst-kix_list_23-2>li:before{content:" "}ul.lst-kix_list_16-2{list-style-type:none}ul.lst-kix_list_16-1{list-style-type:none}ul.lst-kix_list_16-0{list-style-type:none}
.lst-kix_list_23-0>li:before{content:"" counter(lst-ctn-kix_list_23-0,decimal) ". "}
.lst-kix_list_23-8>li:before{content:" "}
.lst-kix_list_3-6>li{counter-increment:lst-ctn-kix_list_3-6}ol.lst-kix_list_27-0{list-style-type:none}
.lst-kix_list_23-1>li:before{content:" "}
.lst-kix_list_24-1>li:before{content:" "}ul.lst-kix_list_16-8{list-style-type:none}ul.lst-kix_list_16-7{list-style-type:none}ul.lst-kix_list_16-6{list-style-type:none}
.lst-kix_list_2-8>li{counter-increment:lst-ctn-kix_list_2-8}ul.lst-kix_list_16-5{list-style-type:none}
.lst-kix_list_24-0>li:before{content:"" counter(lst-ctn-kix_list_24-0,decimal) ". "}ul.lst-kix_list_16-4{list-style-type:none}ul.lst-kix_list_16-3{list-style-type:none}
.lst-kix_list_23-4>li:before{content:" "}
.lst-kix_list_23-5>li:before{content:" "}
.lst-kix_list_43-3>li:before{content:"\0025aa  "}
.lst-kix_list_22-2>li:before{content:" "}
.lst-kix_list_22-6>li:before{content:" "}
.lst-kix_list_43-7>li:before{content:"\0025aa  "}
.lst-kix_list_43-1>li:before{content:"o  "}
.lst-kix_list_8-6>li{counter-increment:lst-ctn-kix_list_8-6}
.lst-kix_list_22-0>li:before{content:"" counter(lst-ctn-kix_list_22-0,decimal) ". "}
.lst-kix_list_22-8>li:before{content:" "}ol.lst-kix_list_4-6.start{counter-reset:lst-ctn-kix_list_4-6 0}ol.lst-kix_list_9-7{list-style-type:none}ol.lst-kix_list_9-8{list-style-type:none}ol.lst-kix_list_3-0.start{counter-reset:lst-ctn-kix_list_3-0 0}ol.lst-kix_list_9-3{list-style-type:none}ol.lst-kix_list_9-4{list-style-type:none}
.lst-kix_list_5-7>li{counter-increment:lst-ctn-kix_list_5-7}ol.lst-kix_list_9-5{list-style-type:none}ol.lst-kix_list_9-6{list-style-type:none}ol.lst-kix_list_9-0{list-style-type:none}
.lst-kix_list_43-5>li:before{content:"\0025aa  "}ol.lst-kix_list_9-1{list-style-type:none}ol.lst-kix_list_9-2{list-style-type:none}
.lst-kix_list_22-4>li:before{content:" "}
.lst-kix_list_41-7>li:before{content:"o  "}
.lst-kix_list_25-5>li:before{content:" "}
.lst-kix_list_25-7>li:before{content:" "}
.lst-kix_list_3-5>li{counter-increment:lst-ctn-kix_list_3-5}ol.lst-kix_list_1-1.start{counter-reset:lst-ctn-kix_list_1-1 0}
.lst-kix_list_6-4>li{counter-increment:lst-ctn-kix_list_6-4}
.lst-kix_list_41-1>li:before{content:"o  "}
.lst-kix_list_40-7>li:before{content:"\0025aa  "}
.lst-kix_list_9-3>li{counter-increment:lst-ctn-kix_list_9-3}
.lst-kix_list_40-5>li:before{content:"\0025aa  "}
.lst-kix_list_41-5>li:before{content:"\0025aa  "}ol.lst-kix_list_2-8.start{counter-reset:lst-ctn-kix_list_2-8 0}ol.lst-kix_list_7-6.start{counter-reset:lst-ctn-kix_list_7-6 0}ul.lst-kix_list_29-1{list-style-type:none}ul.lst-kix_list_29-2{list-style-type:none}ul.lst-kix_list_29-3{list-style-type:none}ul.lst-kix_list_29-4{list-style-type:none}ul.lst-kix_list_29-5{list-style-type:none}ul.lst-kix_list_29-6{list-style-type:none}
.lst-kix_list_41-3>li:before{content:"\0025cf  "}ul.lst-kix_list_29-7{list-style-type:none}ul.lst-kix_list_29-8{list-style-type:none}ol.lst-kix_list_29-0{list-style-type:none}ul.lst-kix_list_18-0{list-style-type:none}ol.lst-kix_list_5-8.start{counter-reset:lst-ctn-kix_list_5-8 0}
.lst-kix_list_1-3>li{counter-increment:lst-ctn-kix_list_1-3}
.lst-kix_list_40-3>li:before{content:"\0025aa  "}ul.lst-kix_list_18-8{list-style-type:none}ul.lst-kix_list_18-7{list-style-type:none}ul.lst-kix_list_18-6{list-style-type:none}ul.lst-kix_list_18-5{list-style-type:none}ul.lst-kix_list_18-4{list-style-type:none}ul.lst-kix_list_18-3{list-style-type:none}ul.lst-kix_list_18-2{list-style-type:none}ol.lst-kix_list_6-0.start{counter-reset:lst-ctn-kix_list_6-0 0}ul.lst-kix_list_18-1{list-style-type:none}
.lst-kix_list_40-1>li:before{content:"o  "}
.lst-kix_list_4-2>li{counter-increment:lst-ctn-kix_list_4-2}ol.lst-kix_list_3-1{list-style-type:none}ol.lst-kix_list_3-2{list-style-type:none}ol.lst-kix_list_3-3{list-style-type:none}ol.lst-kix_list_3-4.start{counter-reset:lst-ctn-kix_list_3-4 0}ul.lst-kix_list_47-0{list-style-type:none}
.lst-kix_list_5-1>li{counter-increment:lst-ctn-kix_list_5-1}ol.lst-kix_list_3-4{list-style-type:none}ul.lst-kix_list_47-2{list-style-type:none}ul.lst-kix_list_47-1{list-style-type:none}ul.lst-kix_list_47-4{list-style-type:none}ol.lst-kix_list_3-0{list-style-type:none}ul.lst-kix_list_47-3{list-style-type:none}
.lst-kix_list_7-1>li{counter-increment:lst-ctn-kix_list_7-1}
.lst-kix_list_21-8>li:before{content:" "}
.lst-kix_list_26-5>li:before{content:" "}
.lst-kix_list_8-0>li{counter-increment:lst-ctn-kix_list_8-0}ul.lst-kix_list_47-6{list-style-type:none}ul.lst-kix_list_47-5{list-style-type:none}ul.lst-kix_list_47-8{list-style-type:none}ul.lst-kix_list_47-7{list-style-type:none}ol.lst-kix_list_3-5{list-style-type:none}ol.lst-kix_list_3-6{list-style-type:none}
.lst-kix_list_26-8>li:before{content:" "}ol.lst-kix_list_3-7{list-style-type:none}ol.lst-kix_list_3-8{list-style-type:none}ul.lst-kix_list_36-0{list-style-type:none}
.lst-kix_list_21-0>li:before{content:"" counter(lst-ctn-kix_list_21-0,decimal) ". "}ul.lst-kix_list_36-1{list-style-type:none}ul.lst-kix_list_36-2{list-style-type:none}
.lst-kix_list_26-1>li:before{content:" "}ul.lst-kix_list_36-3{list-style-type:none}ul.lst-kix_list_36-4{list-style-type:none}
.lst-kix_list_21-1>li:before{content:" "}
.lst-kix_list_26-4>li:before{content:" "}
.lst-kix_list_21-5>li:before{content:" "}ul.lst-kix_list_36-5{list-style-type:none}ul.lst-kix_list_36-6{list-style-type:none}ul.lst-kix_list_36-7{list-style-type:none}ul.lst-kix_list_36-8{list-style-type:none}
.lst-kix_list_21-4>li:before{content:" "}
.lst-kix_list_26-0>li:before{content:"" counter(lst-ctn-kix_list_26-0,decimal) ". "}ul.lst-kix_list_23-1{list-style-type:none}ol.lst-kix_list_7-2.start{counter-reset:lst-ctn-kix_list_7-2 0}ul.lst-kix_list_23-2{list-style-type:none}ul.lst-kix_list_23-3{list-style-type:none}ul.lst-kix_list_23-4{list-style-type:none}ul.lst-kix_list_23-5{list-style-type:none}ul.lst-kix_list_23-6{list-style-type:none}
.lst-kix_list_45-2>li:before{content:"\0025cf  "}
.lst-kix_list_21-0>li{counter-increment:lst-ctn-kix_list_21-0}
.lst-kix_list_25-1>li:before{content:" "}
.lst-kix_list_45-5>li:before{content:"\0025cf  "}ul.lst-kix_list_23-7{list-style-type:none}
.lst-kix_list_25-0>li:before{content:"" counter(lst-ctn-kix_list_25-0,decimal) ". "}ul.lst-kix_list_23-8{list-style-type:none}
.lst-kix_list_45-6>li:before{content:"\0025cf  "}
.lst-kix_list_44-6>li:before{content:"\0025cf  "}ol.lst-kix_list_7-1.start{counter-reset:lst-ctn-kix_list_7-1 0}
.lst-kix_list_39-0>li:before{content:"\0025cf  "}
.lst-kix_list_44-2>li:before{content:"\0025aa  "}ul.lst-kix_list_12-6{list-style-type:none}ul.lst-kix_list_12-5{list-style-type:none}ul.lst-kix_list_12-4{list-style-type:none}
.lst-kix_list_39-1>li:before{content:"o  "}ul.lst-kix_list_12-3{list-style-type:none}ul.lst-kix_list_12-2{list-style-type:none}ul.lst-kix_list_12-1{list-style-type:none}
.lst-kix_list_44-1>li:before{content:"o  "}ul.lst-kix_list_12-0{list-style-type:none}
.lst-kix_list_45-1>li:before{content:"\0025cf  "}ol.lst-kix_list_23-0{list-style-type:none}ul.lst-kix_list_12-8{list-style-type:none}ul.lst-kix_list_12-7{list-style-type:none}
.lst-kix_list_2-2>li{counter-increment:lst-ctn-kix_list_2-2}
.lst-kix_list_44-5>li:before{content:"\0025aa  "}ol.lst-kix_list_4-7.start{counter-reset:lst-ctn-kix_list_4-7 0}ol.lst-kix_list_5-0{list-style-type:none}
.lst-kix_list_3-7>li{counter-increment:lst-ctn-kix_list_3-7}ol.lst-kix_list_5-1{list-style-type:none}ol.lst-kix_list_5-2{list-style-type:none}
.lst-kix_list_22-5>li:before{content:" "}
.lst-kix_list_43-2>li:before{content:"\0025aa  "}
.lst-kix_list_43-6>li:before{content:"\0025aa  "}
.lst-kix_list_22-1>li:before{content:" "}
.lst-kix_list_27-4>li:before{content:" "}
.lst-kix_list_6-6>li{counter-increment:lst-ctn-kix_list_6-6}
.lst-kix_list_39-4>li:before{content:"\0025aa  "}
.lst-kix_list_39-8>li:before{content:"\0025aa  "}ol.lst-kix_list_5-7{list-style-type:none}ol.lst-kix_list_5-8{list-style-type:none}ol.lst-kix_list_5-3{list-style-type:none}
.lst-kix_list_8-7>li{counter-increment:lst-ctn-kix_list_8-7}ol.lst-kix_list_5-4{list-style-type:none}
.lst-kix_list_27-0>li:before{content:"" counter(lst-ctn-kix_list_27-0,decimal) ". "}ol.lst-kix_list_5-5{list-style-type:none}ol.lst-kix_list_5-6{list-style-type:none}
.lst-kix_list_9-5>li{counter-increment:lst-ctn-kix_list_9-5}
.lst-kix_list_5-8>li{counter-increment:lst-ctn-kix_list_5-8}ul.lst-kix_list_38-0{list-style-type:none}ul.lst-kix_list_38-1{list-style-type:none}ul.lst-kix_list_38-2{list-style-type:none}
.lst-kix_list_27-0>li{counter-increment:lst-ctn-kix_list_27-0}ol.lst-kix_list_2-2.start{counter-reset:lst-ctn-kix_list_2-2 0}
.lst-kix_list_25-4>li:before{content:" "}
.lst-kix_list_46-5>li:before{content:"\0025aa  "}ul.lst-kix_list_38-3{list-style-type:none}ul.lst-kix_list_38-4{list-style-type:none}
.lst-kix_list_25-8>li:before{content:" "}ul.lst-kix_list_38-5{list-style-type:none}ul.lst-kix_list_38-6{list-style-type:none}ul.lst-kix_list_38-7{list-style-type:none}ul.lst-kix_list_38-8{list-style-type:none}ul.lst-kix_list_25-1{list-style-type:none}ul.lst-kix_list_25-2{list-style-type:none}ul.lst-kix_list_25-3{list-style-type:none}ul.lst-kix_list_25-4{list-style-type:none}
.lst-kix_list_40-6>li:before{content:"\0025aa  "}
.lst-kix_list_41-6>li:before{content:"\0025cf  "}
.lst-kix_list_20-5>li:before{content:" "}
.lst-kix_list_28-8>li:before{content:" "}ul.lst-kix_list_25-5{list-style-type:none}
.lst-kix_list_41-2>li:before{content:"\0025aa  "}ul.lst-kix_list_25-6{list-style-type:none}
.lst-kix_list_20-1>li:before{content:" "}ul.lst-kix_list_25-7{list-style-type:none}ul.lst-kix_list_25-8{list-style-type:none}ol.lst-kix_list_8-4.start{counter-reset:lst-ctn-kix_list_8-4 0}
.lst-kix_list_28-4>li:before{content:" "}ol.lst-kix_list_3-5.start{counter-reset:lst-ctn-kix_list_3-5 0}ol.lst-kix_list_25-0.start{counter-reset:lst-ctn-kix_list_25-0 0}ul.lst-kix_list_14-4{list-style-type:none}ul.lst-kix_list_14-3{list-style-type:none}ul.lst-kix_list_14-2{list-style-type:none}ul.lst-kix_list_14-1{list-style-type:none}ul.lst-kix_list_14-0{list-style-type:none}ol.lst-kix_list_25-0{list-style-type:none}ul.lst-kix_list_14-8{list-style-type:none}ul.lst-kix_list_14-7{list-style-type:none}ol.lst-kix_list_8-3.start{counter-reset:lst-ctn-kix_list_8-3 0}ul.lst-kix_list_14-6{list-style-type:none}ul.lst-kix_list_14-5{list-style-type:none}
.lst-kix_list_28-0>li:before{content:"" counter(lst-ctn-kix_list_28-0,decimal) ". "}
.lst-kix_list_27-8>li:before{content:" "}
.lst-kix_list_40-2>li:before{content:"\0025aa  "}
.lst-kix_list_4-1>li{counter-increment:lst-ctn-kix_list_4-1}ul.lst-kix_list_43-2{list-style-type:none}ul.lst-kix_list_43-1{list-style-type:none}
.lst-kix_list_19-1>li:before{content:" "}ul.lst-kix_list_43-4{list-style-type:none}ul.lst-kix_list_43-3{list-style-type:none}ul.lst-kix_list_43-6{list-style-type:none}ul.lst-kix_list_43-5{list-style-type:none}ul.lst-kix_list_43-8{list-style-type:none}ul.lst-kix_list_43-7{list-style-type:none}
.lst-kix_list_19-4>li:before{content:" "}
.lst-kix_list_19-3>li:before{content:" "}ul.lst-kix_list_43-0{list-style-type:none}ol.lst-kix_list_6-6.start{counter-reset:lst-ctn-kix_list_6-6 0}
.lst-kix_list_47-5>li:before{content:"\0025cf  "}
.lst-kix_list_47-6>li:before{content:"\0025cf  "}ol.lst-kix_list_1-5.start{counter-reset:lst-ctn-kix_list_1-5 0}
.lst-kix_list_47-8>li:before{content:"\0025cf  "}ul.lst-kix_list_32-1{list-style-type:none}ul.lst-kix_list_32-2{list-style-type:none}ul.lst-kix_list_32-3{list-style-type:none}ul.lst-kix_list_32-4{list-style-type:none}ol.lst-kix_list_9-6.start{counter-reset:lst-ctn-kix_list_9-6 0}ul.lst-kix_list_32-5{list-style-type:none}ul.lst-kix_list_32-6{list-style-type:none}ul.lst-kix_list_32-7{list-style-type:none}ul.lst-kix_list_32-8{list-style-type:none}
.lst-kix_list_47-3>li:before{content:"\0025cf  "}ol.lst-kix_list_4-5.start{counter-reset:lst-ctn-kix_list_4-5 0}ul.lst-kix_list_32-0{list-style-type:none}
.lst-kix_list_37-0>li:before{content:"\0025cf  "}
.lst-kix_list_5-2>li{counter-increment:lst-ctn-kix_list_5-2}
.lst-kix_list_19-6>li:before{content:" "}
.lst-kix_list_47-0>li:before{content:"\0025cf  "}
.lst-kix_list_37-8>li:before{content:"\0025aa  "}ol.lst-kix_list_8-7.start{counter-reset:lst-ctn-kix_list_8-7 0}
.lst-kix_list_37-6>li:before{content:"\0025aa  "}
.lst-kix_list_37-1>li:before{content:"o  "}
.lst-kix_list_46-2>li:before{content:"\0025aa  "}
.lst-kix_list_22-0>li{counter-increment:lst-ctn-kix_list_22-0}
.lst-kix_list_46-1>li:before{content:"o  "}ol.lst-kix_list_24-0.start{counter-reset:lst-ctn-kix_list_24-0 0}
.lst-kix_list_37-3>li:before{content:"\0025aa  "}ol.lst-kix_list_1-0.start{counter-reset:lst-ctn-kix_list_1-0 0}
.lst-kix_list_18-0>li:before{content:"\0025cf  "}
.lst-kix_list_38-7>li:before{content:"\0025aa  "}
.lst-kix_list_3-0>li{counter-increment:lst-ctn-kix_list_3-0}
.lst-kix_list_18-2>li:before{content:" "}
.lst-kix_list_26-0>li{counter-increment:lst-ctn-kix_list_26-0}ol.lst-kix_list_4-0.start{counter-reset:lst-ctn-kix_list_4-0 0}ol.lst-kix_list_27-0.start{counter-reset:lst-ctn-kix_list_27-0 0}
.lst-kix_list_38-2>li:before{content:"\0025aa  "}
.lst-kix_list_38-4>li:before{content:"\0025aa  "}
.lst-kix_list_30-4>li{counter-increment:lst-ctn-kix_list_30-4}
.lst-kix_list_38-5>li:before{content:"\0025aa  "}ul.lst-kix_list_45-0{list-style-type:none}
.lst-kix_list_27-1>li:before{content:" "}ul.lst-kix_list_45-2{list-style-type:none}ul.lst-kix_list_45-1{list-style-type:none}ul.lst-kix_list_45-4{list-style-type:none}
.lst-kix_list_27-3>li:before{content:" "}ul.lst-kix_list_45-3{list-style-type:none}ul.lst-kix_list_45-6{list-style-type:none}ul.lst-kix_list_45-5{list-style-type:none}ol.lst-kix_list_9-4.start{counter-reset:lst-ctn-kix_list_9-4 0}
.lst-kix_list_18-8>li:before{content:" "}ul.lst-kix_list_45-8{list-style-type:none}ul.lst-kix_list_45-7{list-style-type:none}ol.lst-kix_list_30-1.start{counter-reset:lst-ctn-kix_list_30-1 0}ol.lst-kix_list_4-3.start{counter-reset:lst-ctn-kix_list_4-3 0}
.lst-kix_list_7-8>li{counter-increment:lst-ctn-kix_list_7-8}
.lst-kix_list_10-7>li:before{content:" "}ul.lst-kix_list_34-0{list-style-type:none}ul.lst-kix_list_34-1{list-style-type:none}
.lst-kix_list_36-4>li:before{content:"\0025aa  "}ul.lst-kix_list_34-2{list-style-type:none}ul.lst-kix_list_34-3{list-style-type:none}
.lst-kix_list_10-5>li:before{content:" "}ul.lst-kix_list_34-4{list-style-type:none}ul.lst-kix_list_34-5{list-style-type:none}ul.lst-kix_list_34-6{list-style-type:none}
.lst-kix_list_9-2>li:before{content:"(" counter(lst-ctn-kix_list_9-2,lower-roman) ") "}ol.lst-kix_list_20-0{list-style-type:none}
.lst-kix_list_46-4>li:before{content:"o  "}ul.lst-kix_list_34-7{list-style-type:none}ul.lst-kix_list_34-8{list-style-type:none}
.lst-kix_list_5-5>li{counter-increment:lst-ctn-kix_list_5-5}
.lst-kix_list_36-2>li:before{content:"\0025aa  "}
.lst-kix_list_9-0>li:before{content:"" counter(lst-ctn-kix_list_9-0,decimal) ". "}ul.lst-kix_list_21-1{list-style-type:none}ul.lst-kix_list_21-2{list-style-type:none}ul.lst-kix_list_21-3{list-style-type:none}ul.lst-kix_list_21-4{list-style-type:none}ul.lst-kix_list_21-5{list-style-type:none}ul.lst-kix_list_21-6{list-style-type:none}ul.lst-kix_list_21-7{list-style-type:none}ul.lst-kix_list_21-8{list-style-type:none}
.lst-kix_list_11-3>li:before{content:" "}
.lst-kix_list_6-3>li{counter-increment:lst-ctn-kix_list_6-3}ol.lst-kix_list_1-3.start{counter-reset:lst-ctn-kix_list_1-3 0}ol.lst-kix_list_1-2.start{counter-reset:lst-ctn-kix_list_1-2 0}
.lst-kix_list_29-1>li:before{content:" "}
.lst-kix_list_20-4>li:before{content:" "}ol.lst-kix_list_6-1.start{counter-reset:lst-ctn-kix_list_6-1 0}
.lst-kix_list_29-3>li:before{content:" "}
.lst-kix_list_20-2>li:before{content:" "}
.lst-kix_list_9-8>li:before{content:" "}ul.lst-kix_list_10-0{list-style-type:none}
.lst-kix_list_28-7>li:before{content:" "}ul.lst-kix_list_10-8{list-style-type:none}
.lst-kix_list_4-8>li{counter-increment:lst-ctn-kix_list_4-8}
.lst-kix_list_1-7>li:before{content:" "}ul.lst-kix_list_10-7{list-style-type:none}ul.lst-kix_list_10-6{list-style-type:none}ul.lst-kix_list_10-5{list-style-type:none}ul.lst-kix_list_10-4{list-style-type:none}
.lst-kix_list_1-5>li:before{content:" "}ul.lst-kix_list_10-3{list-style-type:none}
.lst-kix_list_28-5>li:before{content:" "}ul.lst-kix_list_10-2{list-style-type:none}ol.lst-kix_list_9-1.start{counter-reset:lst-ctn-kix_list_9-1 0}ul.lst-kix_list_10-1{list-style-type:none}
.lst-kix_list_5-6>li{counter-increment:lst-ctn-kix_list_5-6}
.lst-kix_list_2-1>li:before{content:"(" counter(lst-ctn-kix_list_2-1,lower-latin) ") "}
.lst-kix_list_2-3>li:before{content:"(" counter(lst-ctn-kix_list_2-3,upper-latin) ") "}
.lst-kix_list_35-3>li:before{content:"\0025aa  "}
.lst-kix_list_30-4>li:before{content:"(" counter(lst-ctn-kix_list_30-4,decimal) ") "}
.lst-kix_list_35-6>li:before{content:"\0025aa  "}
.lst-kix_list_9-1>li{counter-increment:lst-ctn-kix_list_9-1}
.lst-kix_list_3-2>li:before{content:"(" counter(lst-ctn-kix_list_3-2,lower-roman) ") "}
.lst-kix_list_26-7>li:before{content:" "}
.lst-kix_list_8-1>li:before{content:"(" counter(lst-ctn-kix_list_8-1,lower-latin) ") "}ol.lst-kix_list_1-8.start{counter-reset:lst-ctn-kix_list_1-8 0}
.lst-kix_list_6-0>li{counter-increment:lst-ctn-kix_list_6-0}
.lst-kix_list_3-5>li:before{content:" "}
.lst-kix_list_30-7>li:before{content:" "}ol.lst-kix_list_30-8.start{counter-reset:lst-ctn-kix_list_30-8 0}
.lst-kix_list_8-6>li:before{content:" "}
.lst-kix_list_26-2>li:before{content:" "}
.lst-kix_list_21-6>li:before{content:" "}ol.lst-kix_list_4-2.start{counter-reset:lst-ctn-kix_list_4-2 0}
.lst-kix_list_21-3>li:before{content:" "}ol.lst-kix_list_30-2.start{counter-reset:lst-ctn-kix_list_30-2 0}
.lst-kix_list_4-4>li{counter-increment:lst-ctn-kix_list_4-4}
.lst-kix_list_45-8>li:before{content:"\0025cf  "}ol.lst-kix_list_6-4.start{counter-reset:lst-ctn-kix_list_6-4 0}
.lst-kix_list_45-3>li:before{content:"\0025cf  "}
.lst-kix_list_17-1>li:before{content:" "}
.lst-kix_list_25-3>li:before{content:" "}ol.lst-kix_list_4-1.start{counter-reset:lst-ctn-kix_list_4-1 0}
.lst-kix_list_16-2>li:before{content:" "}
.lst-kix_list_16-5>li:before{content:" "}
.lst-kix_list_44-4>li:before{content:"o  "}ol.lst-kix_list_30-3.start{counter-reset:lst-ctn-kix_list_30-3 0}
.lst-kix_list_30-7>li{counter-increment:lst-ctn-kix_list_30-7}
.lst-kix_list_44-7>li:before{content:"o  "}
.lst-kix_list_3-3>li{counter-increment:lst-ctn-kix_list_3-3}
.lst-kix_list_39-3>li:before{content:"\0025aa  "}ol.lst-kix_list_6-3.start{counter-reset:lst-ctn-kix_list_6-3 0}
.lst-kix_list_45-0>li:before{content:"\0025cf  "}
.lst-kix_list_17-6>li:before{content:" "}ul.lst-kix_list_41-4{list-style-type:none}
.lst-kix_list_2-6>li:before{content:" "}ul.lst-kix_list_41-3{list-style-type:none}ul.lst-kix_list_41-6{list-style-type:none}ul.lst-kix_list_41-5{list-style-type:none}ul.lst-kix_list_41-8{list-style-type:none}ul.lst-kix_list_41-7{list-style-type:none}
.lst-kix_list_7-5>li:before{content:" "}
.lst-kix_list_27-6>li:before{content:" "}
.lst-kix_list_43-0>li:before{content:"\0025cf  "}
.lst-kix_list_43-8>li:before{content:"\0025aa  "}
.lst-kix_list_22-7>li:before{content:" "}ul.lst-kix_list_41-0{list-style-type:none}ol.lst-kix_list_30-4.start{counter-reset:lst-ctn-kix_list_30-4 0}ul.lst-kix_list_41-2{list-style-type:none}
.lst-kix_list_30-0>li{counter-increment:lst-ctn-kix_list_30-0}ul.lst-kix_list_41-1{list-style-type:none}
.lst-kix_list_34-7>li:before{content:"\0025aa  "}
.lst-kix_list_18-5>li:before{content:" "}
.lst-kix_list_13-6>li:before{content:" "}
.lst-kix_list_6-7>li{counter-increment:lst-ctn-kix_list_6-7}
.lst-kix_list_1-7>li{counter-increment:lst-ctn-kix_list_1-7}
.lst-kix_list_39-6>li:before{content:"\0025aa  "}
.lst-kix_list_7-5>li{counter-increment:lst-ctn-kix_list_7-5}
.lst-kix_list_15-6>li:before{content:" "}
.lst-kix_list_29-0>li{counter-increment:lst-ctn-kix_list_29-0}
.lst-kix_list_31-3>li:before{content:"\0025aa  "}
.lst-kix_list_36-7>li:before{content:"\0025aa  "}ol.lst-kix_list_6-8.start{counter-reset:lst-ctn-kix_list_6-8 0}
.lst-kix_list_10-2>li:before{content:" "}ol.lst-kix_list_1-7.start{counter-reset:lst-ctn-kix_list_1-7 0}
.lst-kix_list_20-7>li:before{content:" "}ol.lst-kix_list_6-5.start{counter-reset:lst-ctn-kix_list_6-5 0}
.lst-kix_list_4-6>li:before{content:" "}
.lst-kix_list_41-8>li:before{content:"\0025aa  "}
.lst-kix_list_25-6>li:before{content:" "}
.lst-kix_list_46-7>li:before{content:"o  "}
.lst-kix_list_41-0>li:before{content:"\0025cf  "}ol.lst-kix_list_6-7.start{counter-reset:lst-ctn-kix_list_6-7 0}
.lst-kix_list_9-5>li:before{content:" "}
.lst-kix_list_29-6>li:before{content:" "}ol.lst-kix_list_22-0.start{counter-reset:lst-ctn-kix_list_22-0 0}
.lst-kix_list_24-0>li{counter-increment:lst-ctn-kix_list_24-0}
.lst-kix_list_33-3>li:before{content:"\0025aa  "}
.lst-kix_list_12-2>li:before{content:" "}
.lst-kix_list_11-6>li:before{content:" "}
.lst-kix_list_32-7>li:before{content:"\0025aa  "}ol.lst-kix_list_30-6.start{counter-reset:lst-ctn-kix_list_30-6 0}
.lst-kix_list_1-2>li:before{content:"(" counter(lst-ctn-kix_list_1-2,lower-roman) ") "}
.lst-kix_list_1-0>li{counter-increment:lst-ctn-kix_list_1-0}li.li-bullet-0:before{margin-left:-18pt;white-space:nowrap;display:inline-block;min-width:18pt}
.lst-kix_list_40-4>li:before{content:"\0025aa  "}ol.lst-kix_list_30-7.start{counter-reset:lst-ctn-kix_list_30-7 0}
.lst-kix_list_28-2>li:before{content:" "}
.lst-kix_list_14-1>li:before{content:" "}
.lst-kix_list_14-3>li:before{content:" "}ul.lst-kix_list_28-1{list-style-type:none}
.lst-kix_list_14-0>li:before{content:"\0025cf  "}
.lst-kix_list_14-4>li:before{content:" "}
.lst-kix_list_6-1>li{counter-increment:lst-ctn-kix_list_6-1}
.lst-kix_list_14-5>li:before{content:" "}
.lst-kix_list_14-7>li:before{content:" "}
.lst-kix_list_14-6>li:before{content:" "}ol.lst-kix_list_7-4.start{counter-reset:lst-ctn-kix_list_7-4 0}
.lst-kix_list_9-0>li{counter-increment:lst-ctn-kix_list_9-0}ul.lst-kix_list_28-2{list-style-type:none}ul.lst-kix_list_28-3{list-style-type:none}ul.lst-kix_list_28-4{list-style-type:none}ul.lst-kix_list_28-5{list-style-type:none}ul.lst-kix_list_28-6{list-style-type:none}ul.lst-kix_list_28-7{list-style-type:none}ul.lst-kix_list_28-8{list-style-type:none}
.lst-kix_list_14-2>li:before{content:" "}ul.lst-kix_list_17-1{list-style-type:none}ul.lst-kix_list_17-0{list-style-type:none}ol.lst-kix_list_26-0{list-style-type:none}
.lst-kix_list_32-2>li:before{content:"\0025aa  "}ul.lst-kix_list_17-8{list-style-type:none}
.lst-kix_list_32-1>li:before{content:"o  "}
.lst-kix_list_32-3>li:before{content:"\0025aa  "}ul.lst-kix_list_17-7{list-style-type:none}ul.lst-kix_list_17-6{list-style-type:none}ul.lst-kix_list_17-5{list-style-type:none}ol.lst-kix_list_3-7.start{counter-reset:lst-ctn-kix_list_3-7 0}ul.lst-kix_list_17-4{list-style-type:none}ul.lst-kix_list_17-3{list-style-type:none}
.lst-kix_list_14-8>li:before{content:" "}ul.lst-kix_list_17-2{list-style-type:none}
.lst-kix_list_32-0>li:before{content:"\0025cf  "}
.lst-kix_list_3-2>li{counter-increment:lst-ctn-kix_list_3-2}
.lst-kix_list_5-0>li:before{content:"" counter(lst-ctn-kix_list_5-0,decimal) ". "}ol.lst-kix_list_6-0{list-style-type:none}ol.lst-kix_list_6-1{list-style-type:none}
.lst-kix_list_5-4>li{counter-increment:lst-ctn-kix_list_5-4}
.lst-kix_list_5-3>li:before{content:"(" counter(lst-ctn-kix_list_5-3,upper-latin) ") "}
.lst-kix_list_5-2>li:before{content:"(" counter(lst-ctn-kix_list_5-2,lower-roman) ") "}
.lst-kix_list_8-3>li{counter-increment:lst-ctn-kix_list_8-3}
.lst-kix_list_5-1>li:before{content:"(" counter(lst-ctn-kix_list_5-1,lower-latin) ") "}
.lst-kix_list_5-7>li:before{content:" "}
.lst-kix_list_5-6>li:before{content:" "}
.lst-kix_list_5-8>li:before{content:" "}ol.lst-kix_list_6-6{list-style-type:none}ol.lst-kix_list_6-7{list-style-type:none}
.lst-kix_list_5-4>li:before{content:"(" counter(lst-ctn-kix_list_5-4,decimal) ") "}ol.lst-kix_list_6-8{list-style-type:none}
.lst-kix_list_5-5>li:before{content:" "}ol.lst-kix_list_6-2{list-style-type:none}ol.lst-kix_list_6-3{list-style-type:none}ol.lst-kix_list_6-4{list-style-type:none}ol.lst-kix_list_6-5{list-style-type:none}
.lst-kix_list_6-1>li:before{content:"(" counter(lst-ctn-kix_list_6-1,lower-latin) ") "}
.lst-kix_list_6-3>li:before{content:"(" counter(lst-ctn-kix_list_6-3,upper-latin) ") "}
.lst-kix_list_6-8>li{counter-increment:lst-ctn-kix_list_6-8}ul.lst-kix_list_39-0{list-style-type:none}
.lst-kix_list_6-0>li:before{content:"" counter(lst-ctn-kix_list_6-0,decimal) ". "}
.lst-kix_list_6-4>li:before{content:"(" counter(lst-ctn-kix_list_6-4,decimal) ") "}ul.lst-kix_list_39-1{list-style-type:none}
.lst-kix_list_6-2>li:before{content:"(" counter(lst-ctn-kix_list_6-2,lower-roman) ") "}
.lst-kix_list_2-5>li{counter-increment:lst-ctn-kix_list_2-5}ol.lst-kix_list_3-2.start{counter-reset:lst-ctn-kix_list_3-2 0}
.lst-kix_list_6-8>li:before{content:" "}ul.lst-kix_list_39-2{list-style-type:none}ul.lst-kix_list_39-3{list-style-type:none}ul.lst-kix_list_39-4{list-style-type:none}
.lst-kix_list_6-5>li:before{content:" "}
.lst-kix_list_6-7>li:before{content:" "}ul.lst-kix_list_39-5{list-style-type:none}ul.lst-kix_list_39-6{list-style-type:none}ul.lst-kix_list_39-7{list-style-type:none}ul.lst-kix_list_39-8{list-style-type:none}
.lst-kix_list_6-6>li:before{content:" "}
.lst-kix_list_7-4>li:before{content:"(" counter(lst-ctn-kix_list_7-4,decimal) ") "}
.lst-kix_list_7-6>li:before{content:" "}ol.lst-kix_list_6-2.start{counter-reset:lst-ctn-kix_list_6-2 0}
.lst-kix_list_7-2>li:before{content:"(" counter(lst-ctn-kix_list_7-2,lower-roman) ") "}
.lst-kix_list_7-6>li{counter-increment:lst-ctn-kix_list_7-6}
.lst-kix_list_34-8>li:before{content:"\0025aa  "}
.lst-kix_list_31-0>li:before{content:"\0025cf  "}
.lst-kix_list_13-7>li:before{content:" "}
.lst-kix_list_7-8>li:before{content:" "}ol.lst-kix_list_23-0.start{counter-reset:lst-ctn-kix_list_23-0 0}
.lst-kix_list_4-7>li{counter-increment:lst-ctn-kix_list_4-7}ol.lst-kix_list_2-5.start{counter-reset:lst-ctn-kix_list_2-5 0}
.lst-kix_list_15-5>li:before{content:" "}
.lst-kix_list_31-6>li:before{content:"\0025aa  "}
.lst-kix_list_31-8>li:before{content:"\0025aa  "}ol.lst-kix_list_26-0.start{counter-reset:lst-ctn-kix_list_26-0 0}
.lst-kix_list_9-8>li{counter-increment:lst-ctn-kix_list_9-8}ol.lst-kix_list_28-0{list-style-type:none}
.lst-kix_list_4-1>li:before{content:"(" counter(lst-ctn-kix_list_4-1,lower-latin) ") "}
.lst-kix_list_31-2>li:before{content:"\0025aa  "}
.lst-kix_list_31-4>li:before{content:"\0025aa  "}
.lst-kix_list_15-7>li:before{content:" "}ul.lst-kix_list_19-7{list-style-type:none}ul.lst-kix_list_19-6{list-style-type:none}
.lst-kix_list_4-3>li:before{content:"(" counter(lst-ctn-kix_list_4-3,upper-latin) ") "}
.lst-kix_list_4-5>li:before{content:" "}ul.lst-kix_list_19-5{list-style-type:none}ul.lst-kix_list_19-4{list-style-type:none}ul.lst-kix_list_19-3{list-style-type:none}
.lst-kix_list_1-8>li{counter-increment:lst-ctn-kix_list_1-8}ul.lst-kix_list_19-2{list-style-type:none}ul.lst-kix_list_19-1{list-style-type:none}ul.lst-kix_list_19-0{list-style-type:none}
.lst-kix_list_15-1>li:before{content:" "}ol.lst-kix_list_1-4.start{counter-reset:lst-ctn-kix_list_1-4 0}
.lst-kix_list_15-3>li:before{content:" "}ul.lst-kix_list_19-8{list-style-type:none}ol.lst-kix_list_4-4.start{counter-reset:lst-ctn-kix_list_4-4 0}
.lst-kix_list_30-2>li{counter-increment:lst-ctn-kix_list_30-2}ol.lst-kix_list_9-2.start{counter-reset:lst-ctn-kix_list_9-2 0}
.lst-kix_list_20-0>li{counter-increment:lst-ctn-kix_list_20-0}
.lst-kix_list_32-4>li:before{content:"\0025aa  "}
.lst-kix_list_33-4>li:before{content:"\0025aa  "}
.lst-kix_list_30-3>li{counter-increment:lst-ctn-kix_list_30-3}ol.lst-kix_list_8-8{list-style-type:none}
.lst-kix_list_12-3>li:before{content:" "}
.lst-kix_list_32-6>li:before{content:"\0025aa  "}ol.lst-kix_list_8-4{list-style-type:none}
.lst-kix_list_12-1>li:before{content:" "}ol.lst-kix_list_8-5{list-style-type:none}
.lst-kix_list_33-0>li:before{content:"\0025cf  "}
.lst-kix_list_33-2>li:before{content:"\0025aa  "}ol.lst-kix_list_8-6{list-style-type:none}ol.lst-kix_list_8-7{list-style-type:none}
.lst-kix_list_32-8>li:before{content:"\0025aa  "}ol.lst-kix_list_8-0{list-style-type:none}ol.lst-kix_list_8-1{list-style-type:none}ol.lst-kix_list_8-2{list-style-type:none}ol.lst-kix_list_8-3{list-style-type:none}
.lst-kix_list_34-0>li:before{content:"\0025cf  "}
.lst-kix_list_13-3>li:before{content:" "}
.lst-kix_list_34-4>li:before{content:"\0025aa  "}
.lst-kix_list_28-0>li{counter-increment:lst-ctn-kix_list_28-0}
.lst-kix_list_34-6>li:before{content:"\0025aa  "}
.lst-kix_list_13-5>li:before{content:" "}
.lst-kix_list_12-5>li:before{content:" "}
.lst-kix_list_12-7>li:before{content:" "}
.lst-kix_list_33-6>li:before{content:"\0025aa  "}
.lst-kix_list_33-8>li:before{content:"\0025aa  "}ol.lst-kix_list_29-0.start{counter-reset:lst-ctn-kix_list_29-0 0}
.lst-kix_list_34-2>li:before{content:"\0025aa  "}
.lst-kix_list_13-1>li:before{content:" "}
.lst-kix_list_30-5>li:before{content:" "}ul.lst-kix_list_24-1{list-style-type:none}ul.lst-kix_list_24-2{list-style-type:none}
.lst-kix_list_35-0>li:before{content:"\0025cf  "}
.lst-kix_list_35-1>li:before{content:"o  "}
.lst-kix_list_35-4>li:before{content:"\0025aa  "}
.lst-kix_list_35-5>li:before{content:"\0025aa  "}ul.lst-kix_list_24-3{list-style-type:none}ul.lst-kix_list_24-4{list-style-type:none}ul.lst-kix_list_24-5{list-style-type:none}
.lst-kix_list_1-1>li{counter-increment:lst-ctn-kix_list_1-1}
.lst-kix_list_30-1>li:before{content:"(" counter(lst-ctn-kix_list_30-1,lower-latin) ") "}ol.lst-kix_list_2-6.start{counter-reset:lst-ctn-kix_list_2-6 0}
.lst-kix_list_3-0>li:before{content:"" counter(lst-ctn-kix_list_3-0,decimal) ". "}
.lst-kix_list_30-2>li:before{content:"(" counter(lst-ctn-kix_list_30-2,lower-roman) ") "}
.lst-kix_list_4-0>li{counter-increment:lst-ctn-kix_list_4-0}ul.lst-kix_list_24-6{list-style-type:none}ul.lst-kix_list_24-7{list-style-type:none}
.lst-kix_list_3-4>li:before{content:"(" counter(lst-ctn-kix_list_3-4,decimal) ") "}ul.lst-kix_list_24-8{list-style-type:none}
.lst-kix_list_3-3>li:before{content:"(" counter(lst-ctn-kix_list_3-3,upper-latin) ") "}
.lst-kix_list_8-0>li:before{content:"" counter(lst-ctn-kix_list_8-0,decimal) ". "}
.lst-kix_list_30-6>li:before{content:" "}
.lst-kix_list_8-7>li:before{content:" "}
.lst-kix_list_3-8>li:before{content:" "}
.lst-kix_list_8-3>li:before{content:"(" counter(lst-ctn-kix_list_8-3,upper-latin) ") "}ul.lst-kix_list_13-5{list-style-type:none}ul.lst-kix_list_13-4{list-style-type:none}ul.lst-kix_list_13-3{list-style-type:none}ul.lst-kix_list_13-2{list-style-type:none}
.lst-kix_list_3-7>li:before{content:" "}ul.lst-kix_list_13-1{list-style-type:none}ul.lst-kix_list_13-0{list-style-type:none}
.lst-kix_list_8-4>li:before{content:"(" counter(lst-ctn-kix_list_8-4,decimal) ") "}ol.lst-kix_list_22-0{list-style-type:none}ol.lst-kix_list_8-5.start{counter-reset:lst-ctn-kix_list_8-5 0}
.lst-kix_list_35-8>li:before{content:"\0025aa  "}ul.lst-kix_list_13-8{list-style-type:none}
.lst-kix_list_11-1>li:before{content:" "}ul.lst-kix_list_13-7{list-style-type:none}ul.lst-kix_list_13-6{list-style-type:none}
.lst-kix_list_11-0>li:before{content:"\0025cf  "}ol.lst-kix_list_9-3.start{counter-reset:lst-ctn-kix_list_9-3 0}
.lst-kix_list_8-8>li:before{content:" "}ol.lst-kix_list_2-2{list-style-type:none}
.lst-kix_list_16-8>li:before{content:" "}ol.lst-kix_list_2-3{list-style-type:none}ol.lst-kix_list_2-4{list-style-type:none}ul.lst-kix_list_46-1{list-style-type:none}
.lst-kix_list_16-7>li:before{content:" "}ol.lst-kix_list_2-5{list-style-type:none}ul.lst-kix_list_46-0{list-style-type:none}ul.lst-kix_list_46-3{list-style-type:none}ul.lst-kix_list_46-2{list-style-type:none}ol.lst-kix_list_2-0{list-style-type:none}ul.lst-kix_list_46-5{list-style-type:none}ol.lst-kix_list_2-1{list-style-type:none}ul.lst-kix_list_46-4{list-style-type:none}
.lst-kix_list_4-8>li:before{content:" "}
.lst-kix_list_4-7>li:before{content:" "}ol.lst-kix_list_20-0.start{counter-reset:lst-ctn-kix_list_20-0 0}
.lst-kix_list_17-0>li:before{content:"\0025cf  "}
.lst-kix_list_16-0>li:before{content:"\0025cf  "}ol.lst-kix_list_4-8.start{counter-reset:lst-ctn-kix_list_4-8 0}
.lst-kix_list_8-4>li{counter-increment:lst-ctn-kix_list_8-4}ul.lst-kix_list_46-7{list-style-type:none}
.lst-kix_list_16-4>li:before{content:" "}ol.lst-kix_list_3-3.start{counter-reset:lst-ctn-kix_list_3-3 0}ul.lst-kix_list_46-6{list-style-type:none}
.lst-kix_list_16-3>li:before{content:" "}ul.lst-kix_list_46-8{list-style-type:none}ol.lst-kix_list_2-6{list-style-type:none}ol.lst-kix_list_2-7{list-style-type:none}ol.lst-kix_list_2-8{list-style-type:none}
.lst-kix_list_30-1>li{counter-increment:lst-ctn-kix_list_30-1}ul.lst-kix_list_35-0{list-style-type:none}ul.lst-kix_list_35-1{list-style-type:none}ul.lst-kix_list_35-2{list-style-type:none}ul.lst-kix_list_35-3{list-style-type:none}ul.lst-kix_list_35-4{list-style-type:none}ul.lst-kix_list_35-5{list-style-type:none}ol.lst-kix_list_8-6.start{counter-reset:lst-ctn-kix_list_8-6 0}
.lst-kix_list_17-7>li:before{content:" "}
.lst-kix_list_17-8>li:before{content:" "}
.lst-kix_list_17-3>li:before{content:" "}
.lst-kix_list_17-4>li:before{content:" "}ul.lst-kix_list_35-6{list-style-type:none}ul.lst-kix_list_35-7{list-style-type:none}ol.lst-kix_list_8-0.start{counter-reset:lst-ctn-kix_list_8-0 0}ul.lst-kix_list_35-8{list-style-type:none}
.lst-kix_list_7-0>li:before{content:"" counter(lst-ctn-kix_list_7-0,decimal) ". "}ol.lst-kix_list_9-7.start{counter-reset:lst-ctn-kix_list_9-7 0}
.lst-kix_list_2-4>li:before{content:"(" counter(lst-ctn-kix_list_2-4,decimal) ") "}
.lst-kix_list_2-8>li:before{content:" "}ul.lst-kix_list_26-1{list-style-type:none}ul.lst-kix_list_26-2{list-style-type:none}ul.lst-kix_list_26-3{list-style-type:none}
.lst-kix_list_7-3>li:before{content:"(" counter(lst-ctn-kix_list_7-3,upper-latin) ") "}
.lst-kix_list_10-0>li:before{content:"\0025cf  "}
.lst-kix_list_9-7>li{counter-increment:lst-ctn-kix_list_9-7}
.lst-kix_list_13-8>li:before{content:" "}
.lst-kix_list_31-1>li:before{content:"o  "}
.lst-kix_list_18-3>li:before{content:" "}
.lst-kix_list_18-7>li:before{content:" "}ul.lst-kix_list_26-4{list-style-type:none}ul.lst-kix_list_26-5{list-style-type:none}ul.lst-kix_list_26-6{list-style-type:none}ul.lst-kix_list_26-7{list-style-type:none}ul.lst-kix_list_26-8{list-style-type:none}ol.lst-kix_list_3-8.start{counter-reset:lst-ctn-kix_list_3-8 0}
.lst-kix_list_7-7>li:before{content:" "}
.lst-kix_list_36-5>li:before{content:"\0025aa  "}ol.lst-kix_list_8-1.start{counter-reset:lst-ctn-kix_list_8-1 0}
.lst-kix_list_15-4>li:before{content:" "}
.lst-kix_list_31-5>li:before{content:"\0025aa  "}
.lst-kix_list_10-4>li:before{content:" "}
.lst-kix_list_10-8>li:before{content:" "}
.lst-kix_list_4-0>li:before{content:"" counter(lst-ctn-kix_list_4-0,decimal) ". "}
.lst-kix_list_36-1>li:before{content:"o  "}ul.lst-kix_list_15-3{list-style-type:none}ul.lst-kix_list_15-2{list-style-type:none}
.lst-kix_list_15-0>li:before{content:"\0025cf  "}ul.lst-kix_list_15-1{list-style-type:none}
.lst-kix_list_15-8>li:before{content:" "}ul.lst-kix_list_15-0{list-style-type:none}ol.lst-kix_list_24-0{list-style-type:none}
.lst-kix_list_4-4>li:before{content:"(" counter(lst-ctn-kix_list_4-4,decimal) ") "}ul.lst-kix_list_15-8{list-style-type:none}ul.lst-kix_list_15-7{list-style-type:none}ul.lst-kix_list_15-6{list-style-type:none}
.lst-kix_list_9-3>li:before{content:"(" counter(lst-ctn-kix_list_9-3,upper-latin) ") "}ul.lst-kix_list_15-5{list-style-type:none}ul.lst-kix_list_15-4{list-style-type:none}ol.lst-kix_list_7-0.start{counter-reset:lst-ctn-kix_list_7-0 0}ol.lst-kix_list_4-0{list-style-type:none}ol.lst-kix_list_4-1{list-style-type:none}ol.lst-kix_list_4-2{list-style-type:none}ol.lst-kix_list_4-3{list-style-type:none}
.lst-kix_list_9-7>li:before{content:" "}
.lst-kix_list_2-4>li{counter-increment:lst-ctn-kix_list_2-4}
.lst-kix_list_29-4>li:before{content:" "}
.lst-kix_list_29-8>li:before{content:" "}ol.lst-kix_list_3-6.start{counter-reset:lst-ctn-kix_list_3-6 0}
.lst-kix_list_32-5>li:before{content:"\0025aa  "}
.lst-kix_list_11-4>li:before{content:" "}
.lst-kix_list_12-4>li:before{content:" "}
.lst-kix_list_5-3>li{counter-increment:lst-ctn-kix_list_5-3}
.lst-kix_list_29-0>li:before{content:"" counter(lst-ctn-kix_list_29-0,decimal) ". "}ol.lst-kix_list_4-8{list-style-type:none}
.lst-kix_list_7-4>li{counter-increment:lst-ctn-kix_list_7-4}
.lst-kix_list_33-1>li:before{content:"o  "}
.lst-kix_list_1-0>li:before{content:"" counter(lst-ctn-kix_list_1-0,decimal) ". "}ol.lst-kix_list_4-4{list-style-type:none}
.lst-kix_list_11-8>li:before{content:" "}ol.lst-kix_list_4-5{list-style-type:none}ol.lst-kix_list_2-0.start{counter-reset:lst-ctn-kix_list_2-0 0}ol.lst-kix_list_4-6{list-style-type:none}
.lst-kix_list_12-0>li:before{content:"\0025cf  "}ol.lst-kix_list_4-7{list-style-type:none}
.lst-kix_list_1-4>li:before{content:"(" counter(lst-ctn-kix_list_1-4,decimal) ") "}
.lst-kix_list_13-0>li:before{content:"\0025cf  "}ul.lst-kix_list_37-0{list-style-type:none}ul.lst-kix_list_37-1{list-style-type:none}ul.lst-kix_list_37-2{list-style-type:none}ul.lst-kix_list_37-3{list-style-type:none}
.lst-kix_list_1-6>li{counter-increment:lst-ctn-kix_list_1-6}
.lst-kix_list_13-4>li:before{content:" "}
.lst-kix_list_34-5>li:before{content:"\0025aa  "}
.lst-kix_list_33-5>li:before{content:"\0025aa  "}
.lst-kix_list_2-0>li:before{content:"" counter(lst-ctn-kix_list_2-0,decimal) ". "}ol.lst-kix_list_2-1.start{counter-reset:lst-ctn-kix_list_2-1 0}
.lst-kix_list_4-5>li{counter-increment:lst-ctn-kix_list_4-5}ul.lst-kix_list_37-4{list-style-type:none}ol.lst-kix_list_9-8.start{counter-reset:lst-ctn-kix_list_9-8 0}
.lst-kix_list_1-8>li:before{content:" "}ul.lst-kix_list_37-5{list-style-type:none}ul.lst-kix_list_37-6{list-style-type:none}ul.lst-kix_list_37-7{list-style-type:none}ul.lst-kix_list_37-8{list-style-type:none}
.lst-kix_list_34-1>li:before{content:"o  "}
.lst-kix_list_12-8>li:before{content:" "}
.lst-kix_list_8-2>li{counter-increment:lst-ctn-kix_list_8-2}ul.lst-kix_list_20-2{list-style-type:none}ul.lst-kix_list_20-3{list-style-type:none}
.lst-kix_list_19-0>li:before{content:"\0025cf  "}ul.lst-kix_list_20-4{list-style-type:none}ul.lst-kix_list_20-5{list-style-type:none}ul.lst-kix_list_20-6{list-style-type:none}ul.lst-kix_list_20-7{list-style-type:none}ul.lst-kix_list_20-8{list-style-type:none}
.lst-kix_list_8-1>li{counter-increment:lst-ctn-kix_list_8-1}ol.lst-kix_list_30-6{list-style-type:none}ol.lst-kix_list_8-2.start{counter-reset:lst-ctn-kix_list_8-2 0}ol.lst-kix_list_30-5{list-style-type:none}
.lst-kix_list_19-2>li:before{content:" "}ol.lst-kix_list_30-8{list-style-type:none}ol.lst-kix_list_3-1.start{counter-reset:lst-ctn-kix_list_3-1 0}ol.lst-kix_list_21-0.start{counter-reset:lst-ctn-kix_list_21-0 0}ol.lst-kix_list_30-7{list-style-type:none}ul.lst-kix_list_20-1{list-style-type:none}ol.lst-kix_list_30-2{list-style-type:none}ol.lst-kix_list_30-1{list-style-type:none}ol.lst-kix_list_30-4{list-style-type:none}ol.lst-kix_list_30-3{list-style-type:none}
.lst-kix_list_47-7>li:before{content:"\0025cf  "}ol.lst-kix_list_30-0{list-style-type:none}
.lst-kix_list_7-0>li{counter-increment:lst-ctn-kix_list_7-0}
.lst-kix_list_47-2>li:before{content:"\0025cf  "}
.lst-kix_list_2-3>li{counter-increment:lst-ctn-kix_list_2-3}
.lst-kix_list_47-4>li:before{content:"\0025cf  "}
.lst-kix_list_1-2>li{counter-increment:lst-ctn-kix_list_1-2}
.lst-kix_list_19-8>li:before{content:" "}
.lst-kix_list_19-5>li:before{content:" "}
.lst-kix_list_47-1>li:before{content:"\0025cf  "}
.lst-kix_list_19-7>li:before{content:" "}
.lst-kix_list_9-2>li{counter-increment:lst-ctn-kix_list_9-2}ul.lst-kix_list_42-3{list-style-type:none}ul.lst-kix_list_42-2{list-style-type:none}ul.lst-kix_list_42-5{list-style-type:none}
.lst-kix_list_37-7>li:before{content:"\0025aa  "}ul.lst-kix_list_42-4{list-style-type:none}ul.lst-kix_list_42-7{list-style-type:none}ul.lst-kix_list_42-6{list-style-type:none}ul.lst-kix_list_42-8{list-style-type:none}
.lst-kix_list_38-0>li:before{content:"\0025cf  "}ul.lst-kix_list_42-1{list-style-type:none}ul.lst-kix_list_42-0{list-style-type:none}
.lst-kix_list_46-3>li:before{content:"\0025cf  "}
.lst-kix_list_38-1>li:before{content:"o  "}
.lst-kix_list_37-2>li:before{content:"\0025aa  "}
.lst-kix_list_37-4>li:before{content:"\0025aa  "}
.lst-kix_list_37-5>li:before{content:"\0025aa  "}
.lst-kix_list_46-0>li:before{content:"\0025cf  "}ul.lst-kix_list_31-2{list-style-type:none}ul.lst-kix_list_31-3{list-style-type:none}ul.lst-kix_list_31-4{list-style-type:none}ul.lst-kix_list_31-5{list-style-type:none}ul.lst-kix_list_31-6{list-style-type:none}ul.lst-kix_list_31-7{list-style-type:none}ul.lst-kix_list_31-8{list-style-type:none}
.lst-kix_list_18-1>li:before{content:" "}
.lst-kix_list_38-8>li:before{content:"\0025aa  "}ul.lst-kix_list_31-0{list-style-type:none}ul.lst-kix_list_31-1{list-style-type:none}
.lst-kix_list_38-3>li:before{content:"\0025aa  "}
.lst-kix_list_38-6>li:before{content:"\0025aa  "}ol.lst-kix_list_2-4.start{counter-reset:lst-ctn-kix_list_2-4 0}ol.lst-kix_list_1-3{list-style-type:none}ol.lst-kix_list_1-4{list-style-type:none}
.lst-kix_list_2-7>li:before{content:" "}
.lst-kix_list_2-7>li{counter-increment:lst-ctn-kix_list_2-7}ul.lst-kix_list_22-1{list-style-type:none}ol.lst-kix_list_1-5{list-style-type:none}ul.lst-kix_list_22-2{list-style-type:none}ol.lst-kix_list_1-6{list-style-type:none}ul.lst-kix_list_22-3{list-style-type:none}ul.lst-kix_list_22-4{list-style-type:none}ol.lst-kix_list_1-0{list-style-type:none}
.lst-kix_list_2-5>li:before{content:" "}ul.lst-kix_list_22-5{list-style-type:none}ol.lst-kix_list_1-1{list-style-type:none}ul.lst-kix_list_22-6{list-style-type:none}ol.lst-kix_list_1-2{list-style-type:none}ul.lst-kix_list_22-7{list-style-type:none}
.lst-kix_list_27-5>li:before{content:" "}
.lst-kix_list_18-6>li:before{content:" "}
.lst-kix_list_39-5>li:before{content:"\0025aa  "}
.lst-kix_list_39-7>li:before{content:"\0025aa  "}
.lst-kix_list_10-1>li:before{content:" "}
.lst-kix_list_18-4>li:before{content:" "}ul.lst-kix_list_22-8{list-style-type:none}
.lst-kix_list_7-7>li{counter-increment:lst-ctn-kix_list_7-7}ol.lst-kix_list_1-7{list-style-type:none}ol.lst-kix_list_1-8{list-style-type:none}
.lst-kix_list_36-6>li:before{content:"\0025aa  "}
.lst-kix_list_10-3>li:before{content:" "}ul.lst-kix_list_11-7{list-style-type:none}
.lst-kix_list_2-6>li{counter-increment:lst-ctn-kix_list_2-6}ul.lst-kix_list_11-6{list-style-type:none}ol.lst-kix_list_7-3.start{counter-reset:lst-ctn-kix_list_7-3 0}
.lst-kix_list_36-0>li:before{content:"\0025cf  "}
.lst-kix_list_36-8>li:before{content:"\0025aa  "}ul.lst-kix_list_11-5{list-style-type:none}ul.lst-kix_list_11-4{list-style-type:none}ul.lst-kix_list_11-3{list-style-type:none}ul.lst-kix_list_11-2{list-style-type:none}ul.lst-kix_list_11-1{list-style-type:none}ul.lst-kix_list_11-0{list-style-type:none}ol.lst-kix_list_5-7.start{counter-reset:lst-ctn-kix_list_5-7 0}ul.lst-kix_list_11-8{list-style-type:none}
.lst-kix_list_20-8>li:before{content:" "}
.lst-kix_list_46-6>li:before{content:"\0025cf  "}
.lst-kix_list_46-8>li:before{content:"\0025aa  "}
.lst-kix_list_3-4>li{counter-increment:lst-ctn-kix_list_3-4}
.lst-kix_list_29-7>li:before{content:" "}ul.lst-kix_list_44-1{list-style-type:none}ul.lst-kix_list_44-0{list-style-type:none}ul.lst-kix_list_44-3{list-style-type:none}ul.lst-kix_list_44-2{list-style-type:none}
.lst-kix_list_29-5>li:before{content:" "}ul.lst-kix_list_44-5{list-style-type:none}
.lst-kix_list_20-0>li:before{content:"" counter(lst-ctn-kix_list_20-0,decimal) ". "}ul.lst-kix_list_44-4{list-style-type:none}ul.lst-kix_list_44-7{list-style-type:none}ul.lst-kix_list_44-6{list-style-type:none}
.lst-kix_list_9-6>li:before{content:" "}
.lst-kix_list_9-4>li:before{content:"(" counter(lst-ctn-kix_list_9-4,decimal) ") "}
.lst-kix_list_30-8>li{counter-increment:lst-ctn-kix_list_30-8}
.lst-kix_list_20-6>li:before{content:" "}
.lst-kix_list_23-0>li{counter-increment:lst-ctn-kix_list_23-0}
.lst-kix_list_11-5>li:before{content:" "}ul.lst-kix_list_44-8{list-style-type:none}
.lst-kix_list_1-1>li:before{content:"(" counter(lst-ctn-kix_list_1-1,lower-latin) ") "}
.lst-kix_list_11-7>li:before{content:" "}ul.lst-kix_list_33-0{list-style-type:none}
.lst-kix_list_8-5>li{counter-increment:lst-ctn-kix_list_8-5}ul.lst-kix_list_33-1{list-style-type:none}
.lst-kix_list_1-3>li:before{content:"(" counter(lst-ctn-kix_list_1-3,upper-latin) ") "}ul.lst-kix_list_33-2{list-style-type:none}ul.lst-kix_list_33-3{list-style-type:none}ul.lst-kix_list_33-4{list-style-type:none}ul.lst-kix_list_33-5{list-style-type:none}ul.lst-kix_list_33-6{list-style-type:none}
.lst-kix_list_28-3>li:before{content:" "}ul.lst-kix_list_33-7{list-style-type:none}ol.lst-kix_list_2-7.start{counter-reset:lst-ctn-kix_list_2-7 0}ol.lst-kix_list_21-0{list-style-type:none}ol.lst-kix_list_7-5.start{counter-reset:lst-ctn-kix_list_7-5 0}
.lst-kix_list_27-7>li:before{content:" "}ul.lst-kix_list_33-8{list-style-type:none}
.lst-kix_list_28-1>li:before{content:" "}
.lst-kix_list_35-2>li:before{content:"\0025aa  "}
.lst-kix_list_3-1>li{counter-increment:lst-ctn-kix_list_3-1}
.lst-kix_list_30-3>li:before{content:"(" counter(lst-ctn-kix_list_30-3,upper-latin) ") "}
.lst-kix_list_30-8>li:before{content:" "}
.lst-kix_list_35-7>li:before{content:"\0025aa  "}
.lst-kix_list_30-5>li{counter-increment:lst-ctn-kix_list_30-5}ol.lst-kix_list_7-7.start{counter-reset:lst-ctn-kix_list_7-7 0}
.lst-kix_list_3-1>li:before{content:"(" counter(lst-ctn-kix_list_3-1,lower-latin) ") "}
.lst-kix_list_26-6>li:before{content:" "}
.lst-kix_list_44-0>li:before{content:"\0025cf  "}
.lst-kix_list_8-2>li:before{content:"(" counter(lst-ctn-kix_list_8-2,lower-roman) ") "}
.lst-kix_list_21-2>li:before{content:" "}
.lst-kix_list_8-5>li:before{content:" "}
.lst-kix_list_2-0>li{counter-increment:lst-ctn-kix_list_2-0}ol.lst-kix_list_28-0.start{counter-reset:lst-ctn-kix_list_28-0 0}
.lst-kix_list_26-3>li:before{content:" "}
.lst-kix_list_3-6>li:before{content:" "}
.lst-kix_list_21-7>li:before{content:" "}ol.lst-kix_list_5-0.start{counter-reset:lst-ctn-kix_list_5-0 0}
.lst-kix_list_11-2>li:before{content:" "}
.lst-kix_list_16-6>li:before{content:" "}
.lst-kix_list_25-2>li:before{content:" "}ol.lst-kix_list_5-6.start{counter-reset:lst-ctn-kix_list_5-6 0}
.lst-kix_list_16-1>li:before{content:" "}
.lst-kix_list_7-3>li{counter-increment:lst-ctn-kix_list_7-3}
.lst-kix_list_25-0>li{counter-increment:lst-ctn-kix_list_25-0}
.lst-kix_list_45-4>li:before{content:"\0025cf  "}
.lst-kix_list_45-7>li:before{content:"\0025cf  "}ol.lst-kix_list_7-8.start{counter-reset:lst-ctn-kix_list_7-8 0}
.lst-kix_list_44-8>li:before{content:"\0025aa  "}
.lst-kix_list_44-3>li:before{content:"\0025cf  "}
.lst-kix_list_39-2>li:before{content:"\0025aa  "}
.lst-kix_list_17-2>li:before{content:" "}ol.lst-kix_list_5-5.start{counter-reset:lst-ctn-kix_list_5-5 0}
.lst-kix_list_30-0>li:before{content:"" counter(lst-ctn-kix_list_30-0,decimal) ". "}
.lst-kix_list_17-5>li:before{content:" "}
.lst-kix_list_6-2>li{counter-increment:lst-ctn-kix_list_6-2}
.lst-kix_list_27-2>li:before{content:" "}
.lst-kix_list_43-4>li:before{content:"\0025aa  "}
.lst-kix_list_22-3>li:before{content:" "}
.lst-kix_list_7-1>li:before{content:"(" counter(lst-ctn-kix_list_7-1,lower-latin) ") "}
.lst-kix_list_9-6>li{counter-increment:lst-ctn-kix_list_9-6}ol.lst-kix_list_5-4.start{counter-reset:lst-ctn-kix_list_5-4 0}ol.lst-kix_list_5-1.start{counter-reset:lst-ctn-kix_list_5-1 0}
.lst-kix_list_31-7>li:before{content:"\0025aa  "}
.lst-kix_list_3-8>li{counter-increment:lst-ctn-kix_list_3-8}
.lst-kix_list_4-6>li{counter-increment:lst-ctn-kix_list_4-6}
.lst-kix_list_1-5>li{counter-increment:lst-ctn-kix_list_1-5}
.lst-kix_list_4-2>li:before{content:"(" counter(lst-ctn-kix_list_4-2,lower-roman) ") "}
.lst-kix_list_15-2>li:before{content:" "}
.lst-kix_list_36-3>li:before{content:"\0025aa  "}
.lst-kix_list_9-1>li:before{content:"(" counter(lst-ctn-kix_list_9-1,lower-latin) ") "}
.lst-kix_list_10-6>li:before{content:" "}ul.lst-kix_list_40-5{list-style-type:none}ul.lst-kix_list_40-4{list-style-type:none}ul.lst-kix_list_40-7{list-style-type:none}ul.lst-kix_list_40-6{list-style-type:none}ul.lst-kix_list_40-8{list-style-type:none}
.lst-kix_list_40-8>li:before{content:"\0025aa  "}ul.lst-kix_list_40-1{list-style-type:none}ul.lst-kix_list_40-0{list-style-type:none}ul.lst-kix_list_40-3{list-style-type:none}ul.lst-kix_list_40-2{list-style-type:none}
.lst-kix_list_41-4>li:before{content:"o  "}
.lst-kix_list_20-3>li:before{content:" "}
.lst-kix_list_29-2>li:before{content:" "}
.lst-kix_list_8-8>li{counter-increment:lst-ctn-kix_list_8-8}
.lst-kix_list_28-6>li:before{content:" "}
.lst-kix_list_1-6>li:before{content:" "}
.lst-kix_list_33-7>li:before{content:"\0025aa  "}
.lst-kix_list_40-0>li:before{content:"\0025cf  "}
.lst-kix_list_12-6>li:before{content:" "}
.lst-kix_list_34-3>li:before{content:"\0025aa  "}
.lst-kix_list_2-2>li:before{content:"(" counter(lst-ctn-kix_list_2-2,lower-roman) ") "}
.lst-kix_list_13-2>li:before{content:" "}ol.lst-kix_list_5-2.start{counter-reset:lst-ctn-kix_list_5-2 0}ol{margin:0;padding:0}table td,table th{padding:0}
.c0{background-color:#ffffff;margin-left:24pt;padding-top:12pt;padding-left:0pt;padding-bottom:12pt;line-height:1.0;orphans:2;widows:2;text-align:justify;margin-right:12pt}
.c22{margin-left:24pt;padding-top:0pt;padding-left:0pt;padding-bottom:0pt;line-height:1.0;orphans:2;widows:2;text-align:justify}
.c1{color:#000000;font-weight:700;text-decoration:none;vertical-align:baseline;font-size:14pt;font-family:"Inter";font-style:normal}
.c3{background-color:#ffffff;padding-top:12pt;padding-bottom:12pt;line-height:1.0;orphans:2;widows:2;text-align:justify}
.c5{background-color:#ffffff;padding-top:12pt;padding-bottom:12pt;line-height:1.0;orphans:2;widows:2;text-align:left}
.c6{color:#212529;font-weight:400;text-decoration:none;vertical-align:baseline;font-size:11pt;font-family:"Inter";font-style:normal}
.c25{padding-top:0pt;padding-bottom:0pt;line-height:1.0;orphans:2;widows:2;text-align:right;height:12pt}
.c28{color:#000000;font-weight:400;text-decoration:none;vertical-align:baseline;font-size:10pt;font-family:"Calibri"}
.c23{padding-top:0pt;padding-bottom:0pt;line-height:1.0;orphans:2;widows:2;text-align:left}
.c2{font-size:11pt;font-family:"Inter";color:#000000;font-weight:400}
.c13{font-size:18pt;font-family:"Inter";color:#000000;font-weight:400}
.c21{font-size:18pt;font-family:"Inter";color:#000000;font-weight:700}
.c24{color:#000000;font-weight:400;font-size:14pt;font-family:"Inter"}
.c29{color:#000000;font-weight:400;font-size:12pt;font-family:"Times New Roman"}
.c16{color:#000000;font-weight:700;font-size:11pt;font-family:"Inter"}
.c10{text-decoration:none;vertical-align:baseline;font-style:normal}
.c14{-webkit-text-decoration-skip:none;text-decoration:underline;text-decoration-skip-ink:none}
.c18{background-color:#ffffff;max-width:468pt;padding:72pt 72pt 72pt 72pt}
.c7{font-size:11pt;font-family:"Inter";font-weight:400}
.c27{font-size:8pt;font-family:"Inter";font-weight:400}
.c8{padding:0;margin:0}
.c19{margin-left:22.5pt;padding-left:0pt}
.c15{margin-left:36pt;padding-left:0pt}
.c4{color:inherit;text-decoration:inherit}
.c9{margin-left:72pt;padding-left:0pt}
.c17{color:#0563c1}
.c12{margin-right:12pt}
.c26{height:12pt}
.c11{color:#212529}
.c20{font-style:italic}
}