// .ha-website-select-slanted {
//   .ha-popover-content {
//     transform: translate(0.5%, -2%) rotateX(5deg) rotateY(1deg) rotateZ(-1deg);
//   }
// }

// .ha-website-use-case.ha-website-select-slanted {
//   .ha-popover-content {
//     transform: translate(0.25%, -4%) rotateX(5deg) rotateY(1deg) rotateZ(-1deg);
//   }
// }

@keyframes dialogAppear {
  0% {
    transform: translate(-50%, -50%) scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, 10%) scale(1, 1.1);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1, 1);
    opacity: 1;
  }
}

@keyframes tableAppear {
  0% {
    transform: translate(-100%, 0) scale(1, 1);
    opacity: 0;
  }
  50% {
    transform: translate(-10%, 0%) scale(1, 1);
    opacity: 0.5;
  }
  100% {
    transform: translate(0%, 0%) scale(1, 1);
    opacity: 1;
  }
}

.ha-website-dialog-appear {
  .ha-confirmation {
    animation: dialogAppear 0.3s forwards ease-in-out;
  }
}

.ha-website-demo-scroll-area {
  animation: tableAppear 0.3s forwards ease-in-out;
}
.ha-website-display-table-appear {
  position: relative;
  z-index: 2;
  animation: tableAppear 0.3s forwards ease-in-out;
}

@keyframes badgePulse {
  0% {
    outline: solid;
    outline-color: #3f20fd !important;
    outline-offset: 0;
    outline-width: 0;
  }
  50% {
    outline: solid;
    outline-color: #3f20fd !important;
    outline-offset: 8px;
    outline-width: 8px;
  }
  100% {
    outline: solid;
    outline-color: #3f20fd !important;
    outline-offset: 0;
    outline-width: 0;
  }
}

.ha-website-badge-pulse {
  animation: badgePulse 1s infinite linear !important;
}
