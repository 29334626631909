.ha-website-components {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.ha-website-component-tag {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3rem;
  z-index: 1;
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 0 2px #3f20fd;
  user-select: none;

  span {
    z-index: 1;
  }

  .ha-website-component-tag-progress {
    background-color: #3f20fd;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    left: 0;
  }
}
