.ha-website-navigation {
  display: flex;
  position: sticky;
  top: 0;
  background-color: rgba(white, 1);
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-sizing: border-box;
  z-index: 100;
  transition: all 0.1s ease-in;

  &.dark {
    background-color: rgba(#080324, 0.95);

    #labelDecoration path {
      fill: white !important;
    }
  }

  .ha-website-navigation-content {
    width: 100%;
    max-width: 1280px;
    padding-left: 4rem;
    padding-right: 4rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;

    .ha-website-navigation-content-items {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &.ha-website-navigation-is-open {
    box-shadow: 0 0 0 20000px rgba(#080324, 0.8),
      inset 0 0 0 2000px rgba(#080324, 0.05);
  }
}

.ha-website-navigation-content-deep-item {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;

  outline-offset: 0px;
  outline-width: 0px;
  outline-style: solid;
  outline-color: rgba(#3f20fd, 0);
  color: #3f20fd;
  text-decoration: none;
  border-radius: 0.125rem;
  transition: all 0.1s ease-in;

  &:focus-visible {
    outline-offset: 2px;
    outline-width: 2px;
    outline-style: solid;
    outline-color: #3f20fd;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(#3f20fd, 0.2);
  }

  // &.dark {
  //     outline-color: rgba(#8C79FE, 0);
  //     color: #8C79FE;

  //     &:focus-visible {
  //         outline-offset: 2px;
  //         outline-width: 2px;
  //         outline-style: solid;
  //         outline-color: #8C79FE;
  //     }

  //     &:hover {
  //         cursor: pointer;
  //         background-color: rgba(#8C79FE, 0.2);
  //     }
  // }
}

@keyframes menuAppear {
  0% {
    transform: translate(-50%, 75%) scale(0, 0);
    opacity: 0;
    box-shadow: 0 16px 48px rgba(#080324, 0.2);
  }
  50% {
    transform: translate(-50%, -2%) scale(1, 1.1);
    opacity: 0.5;
    box-shadow: 0 16px 24px rgba(#080324, 1);
  }
  100% {
    transform: translate(-50%, 0%) scale(1, 1);
    opacity: 1;
    box-shadow: 0 0px 0px rgba(#080324, 0);
  }
}

.ha-website-navigation-content-mobile {
  .ha-website-navigation-content-item {
    width: 100%;
  }
  .ha-website-navigation-content-deep-item {
    width: 100%;
    box-sizing: border-box;

    &:hover {
      background-color: unset !important;
    }
    h6.web {
      font-size: 1.25rem;
    }
  }
}
.ha-website-navigation-content-item {
  display: inline-flex;
  height: 2rem;
  position: relative;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  line-height: 2rem;
  color: #3f20fd;
  font-weight: 600;
  white-space: nowrap;
  overflow: visible;
  text-decoration: none;

  outline-offset: 0px;
  outline-width: 0px;
  outline-style: solid;
  outline-color: rgba(#3f20fd, 0);

  transition: all 0.1s ease-in;

  &:focus-visible {
    border-radius: 0.125rem;
    outline-offset: 2px;
    outline-width: 2px;
    outline-style: solid;
    outline-color: #3f20fd;
  }

  &:not(:last-child) {
    margin-right: 1.5rem;
  }

  &.active,
  &:hover {
    cursor: pointer;
    box-shadow: inset 0 -2px 0 0 #3f20fd;
  }

  .ha-website-navigation-content-item-children {
    background-color: white;
    border-radius: 0.25rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 1;
    transition: all 0.1s ease-in;
    white-space: nowrap;
    animation: menuAppear 0.3s forwards ease-in-out;
  }
}

.ha-website-navigation-mobile,
.ha-website-navigation-content-mobile {
  #labelDecoration {
    display: none;
  }
  display: none;

  .ha-website-navigation-content-item {
    &:first-of-type {
      display: flex;
      flex-direction: column;
    }
  }
  .ha-website-navigation-content-item-children {
    animation: none;
  }
}

.dark
  .ha-website-navigation-content-mobile
  .ha-website-navigation-content-item.active,
.dark
  .ha-website-navigation-content-mobile
  .ha-website-navigation-content-item:hover {
  box-shadow: none !important;
  //   background-color: none !important;
}

.dark {
  .ha-website-navigation-content-item {
    outline-color: rgba(white, 0);
    color: white;

    &:focus-visible {
      outline-color: white;
    }

    &.active,
    &:hover {
      cursor: pointer;
      box-shadow: inset 0 -2px 0 0 white;
    }
  }

  .ha-website-navigation-content {
    .ha-website-button {
      box-shadow: 0px 1px 1px rgba(12, 12, 14, 0.5),
        0px 2px 4px rgba(12, 12, 12, 1);
      outline-color: rgba(white, 0);

      &:hover {
        box-shadow: inset 0px 0px 100px rgba(12, 12, 14, 0.1),
          0px 1px 1px rgba(12, 12, 14, 0.5), 0px 2px 4px rgba(12, 12, 12, 0.8);
      }

      &:active {
        box-shadow: inset 0px 0px 100px rgba(12, 12, 14, 0.3),
          0px 1px 1px rgba(12, 12, 14, 0.1);
      }

      &:focus-visible {
        outline-color: white;
      }
    }
  }

  .ha-website-navigation-content-mobile {
    .ha-website-navigation-content-item-children {
      color: white;

      h6.web {
        color: white;
      }

      .ha-website-text-content {
        color: white;
      }

      path[fill="#080324"] {
        fill: white;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .ha-website-navigation .ha-website-navigation-content {
    max-width: 1280px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .ha-website-navigation-desktop {
    display: none !important;
  }
  .ha-website-navigation-mobile {
    display: inherit;
  }
  .ha-website-navigation-content-mobile {
    width: 100%;
    max-width: 1280px;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 2rem;
    .ha-website-navigation-content-item {
      height: auto;
      box-shadow: none;
      padding-bottom: 1rem;
    }
    .ha-website-navigation-content-deep-item {
      padding-left: 0;
    }
    .ha-website-navigation-content-item-children {
      background-color: unset;
      border-radius: 0px;
      padding: 0rem;
      display: flex;
      flex-direction: column;
      position: relative !important;
      top: unset !important;
      left: unset !important;
      transform: none !important;
      z-index: 1;
      transition: all 0.1s ease-in;
    }
  }
}

@media screen and (max-width: 800px) {
  .ha-website-navigation-content-item {
    font-size: 1.25rem;
  }
}
