@keyframes appearCanvas {
  0% {
    transform: translate(-50%, 75%);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, -2%);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes appearContent {
  0% {
    transform: translate(-50%, 75%);
  }
  50% {
    transform: translate(-50%, -2%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.ha-website-star-content {
  animation: appearContent 0.3s forwards ease-in-out;
}

.ha-website-3d-canvas {
  animation: appearCanvas 0.3s forwards ease-in-out;
}

.ha-website-star-content {
  transition: all 0.3s ease-in-out;
}

.ha-website-star {
  display: flex;
  position: absolute;
  overflow: visible;
  user-select: none;

  .ha-website-star-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  .ha-website-star-loading {
    position: absolute;
    z-index: 2;
    pointer-events: none;
    path:not(.inner) {
      // strokeWidth: 0.75;
      stroke: black;
      stroke-linecap: square;
    }
  }
  .ha-website-star-loading.animate {
    path {
      opacity: 1;
      animation: dash infinite forwards linear;
    }
  }
}

@keyframes dash {
  to {
    // opacity: 0;
    // stroke: #3F20FD;
    // stroke-width: 1;
    stroke-dashoffset: 0;
    // stroke-dasharray: 0;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ha-website-star {
  > svg {
    width: 100%;
    height: 100%;
    animation: rotate 32s infinite forwards linear;
  }
}
